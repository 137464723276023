import React, { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';

// components and helpers
import { PieChart } from '@acoer/ac-js-lib-charts';
import { explorerPallet } from '../../common/utils/pallet';
import TxStatsSkeletonLoading from '../Shared/LoadingSkeletons/TxTypesSkeleton';
import { TX_TYPE_QUERY } from '../../graphql/gqlQueries';
import { typeFormat } from '../Search/helpers/utils';

// mui
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { IChartData } from '../../common/model/ExplorerModels';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material';
import { ILatestTxListProps } from './LatestTxList';

const TxPieChart: React.FC<ILatestTxListProps> = ({ frame }) => {
    const [chartValues, setChartValues] = useState<IChartData[]>([]);
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));

    const [getTxStatData, { loading: txStatDataLoading }] = useLazyQuery(TX_TYPE_QUERY, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'cache-first',
        nextFetchPolicy: 'cache-first',
        onCompleted: (data) => {
            const typesChartData: Array<IChartData> = [];
            const typesChartTotal = data.getTransactionTypeStats.reduce(
                (a: any, b: any) => +a + +b.numberOfTransactions,
                0,
            );
            let otherType: any = {};
            const txStats = [...data.getTransactionTypeStats];
            const sortedData = txStats.sort((a: any, b: any) => {
                return b.numberOfTransactions - a.numberOfTransactions;
            });
            sortedData.map((type: any, i: number) => {
                const percentage = (type.numberOfTransactions / typesChartTotal) * 100;
                if (typesChartData.length < 6 && percentage > 1) {
                    typesChartData.push({
                        id: type.transactionType,
                        label: typeFormat(type.transactionType),
                        value: type.numberOfTransactions,
                    });
                }
            });
            const otherValues = sortedData.slice(typesChartData.length, data.getTransactionTypeStats.length);
            const result = otherValues.reduce(function (acc: any, obj: any) {
                return acc + obj.numberOfTransactions;
            }, 0);

            if (result > 0) {
                otherType = {
                    id: 'OTHER',
                    label: 'Other',
                    value: result,
                };
                typesChartData.push(otherType);
            }

            setChartValues(typesChartData);
        },
    });

    useEffect(() => {
        getTxStatData();
    }, []);

    return (
        <Grid>
            {txStatDataLoading ? (
                <TxStatsSkeletonLoading />
            ) : (
                <Paper
                    style={{ height: frame ? 283 : 270, borderRadius: 15, padding: '15px 10px 30px 10px' }}
                    elevation={0}
                >
                    <PieChart
                        data={chartValues}
                        onDownloadCsv={() => console.log('download')}
                        chartTitle={'Transaction Service Types in Last 24 Hours'}
                        legendPosition={matches ? 'right' : 'bottom'}
                        primaryText={theme.palette.mode === 'light' ? theme.palette.text.primary : '#D3D3D3'}
                        showToolbar={matches}
                        customVariant={'h6'}
                        customClasses={{ fontWeight: 'bold', fontFamily: 'Inter, sans-serif' }}
                        colourScheme={explorerPallet}
                        showPieValue={false}
                        chartTheme={theme.palette.mode}
                    />
                </Paper>
            )}
        </Grid>
    );
};

export default TxPieChart;
