import React from 'react';
import { transactionTypes } from './helpers/utils';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { typeFormat } from '../Search/helpers/utils';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

interface ITransactionCustomToolbarProps {
    value: string;
    handleChangeFilter: (value: string) => void;
}
const TransactionCustomToolbar: React.FC<ITransactionCustomToolbarProps> = ({ value, handleChangeFilter }) => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));
    return (
        <React.Fragment>
            <FormControl style={{ width: matches ? 250 : 100, fontFamily: 'Inter, sans-serif' }} size="small">
                <InputLabel htmlFor="select-multiple-chip" style={{ fontFamily: 'Inter, sans-serif' }}>
                    Type
                </InputLabel>
                <Select
                    label={'Name'}
                    value={value}
                    onChange={(event) => {
                        handleChangeFilter(event.target.value);
                    }}
                    style={{ fontFamily: 'Inter, sans-serif', fontSize: 12 }}
                >
                    {transactionTypes.map((item) => (
                        <MenuItem key={item} value={item} style={{ fontFamily: 'Inter, sans-serif', fontSize: 12 }}>
                            {typeFormat(item)}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </React.Fragment>
    );
};

export default TransactionCustomToolbar;
