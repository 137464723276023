import React, { useCallback, useState } from 'react';

// components and helpers
import Footer from '../components/Shared/Footer';
import NavBar from '../components/Shared/NavBar';
import AppProvider from './AppProviderWrapper';
import Searchbar from '../components/Search/Searchbar';
import Notification from '../components/Notifications/Notification';
import darkBackground from '../assets/images/background_dark.png';
import lightBackground from '../assets/images/background_light.png';

// mui
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Tooltip from '@mui/material/Tooltip';
import Fab from '@mui/material/Fab';
import Popover from '@mui/material/Popover';
import Contact from '../components/Contact/Contact';
import CommentIcon from '@mui/icons-material/Comment';
import Typography from '@mui/material/Typography';

function ComponentWrapper<T>(WrappedComponent: React.ComponentType<T>): (props: T) => JSX.Element {
    const ComponentWithWrapper = (props: T) => {
        const theme = useTheme();
        const matches = useMediaQuery(theme.breakpoints.up('sm'));
        const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
        const [invalidSearch, setInvalidSearch] = useState<boolean>(false);

        const handleClickOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
            setAnchorEl(event.currentTarget);
        };

        const handleClose = () => {
            setAnchorEl(null);
        };
        const open = Boolean(anchorEl);
        const id = open ? 'simple-popover' : undefined;
        return (
            <AppProvider>
                <div style={{ padding: '0 0 20px', position: 'relative', minHeight: '100vh' }}>
                    <Grid container justifyContent={'center'}>
                        <Grid
                            item
                            xs={12}
                            style={{
                                backgroundImage:
                                    theme.palette.mode === 'dark'
                                        ? `url(${darkBackground})`
                                        : `url(${lightBackground})`,
                                backgroundSize: 'cover',
                                height: 290,
                                marginBottom: 20,
                            }}
                        >
                            <Grid container justifyContent={'center'}>
                                <Grid item xs={12} style={{ marginBottom: 90 }}>
                                    <NavBar />
                                </Grid>
                                <Grid item xs={11} md={10}>
                                    <Grid
                                        container
                                        justifyContent={'center'}
                                        style={{ paddingBottom: 20, marginTop: 50 }}
                                    >
                                        <Grid item xs={12} md={8}>
                                            <Searchbar invalid={invalidSearch} setInvalid={setInvalidSearch} />
                                            {invalidSearch && (
                                                <Grid item>
                                                    <Typography
                                                        variant={'caption'}
                                                        style={{ color: 'orangered', textAlign: 'center' }}
                                                    >
                                                        Invalid Entry. Please check your search value and try again.
                                                    </Typography>
                                                </Grid>
                                            )}
                                        </Grid>
                                        {!matches && !invalidSearch && (
                                            <Grid item xs={12}>
                                                <Typography
                                                    variant={'caption'}
                                                    style={{ color: '#fff', textAlign: 'center' }}
                                                >
                                                    Search accounts, transactions, tokens, contracts, topics and
                                                    schedules
                                                </Typography>
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} style={{ minHeight: '40vh' }}>
                            <WrappedComponent {...props} />
                        </Grid>
                        <Grid item md={10} xs={11} style={{ marginTop: 50 }}>
                            <Footer handleClickOpen={handleClickOpen} />
                        </Grid>
                        {matches && (
                            <Grid item xs={12}>
                                <Tooltip title={'Contact Us'}>
                                    <Fab
                                        size="small"
                                        color="primary"
                                        aria-label="add"
                                        onClick={handleClickOpen}
                                        sx={{
                                            position: matches ? 'fixed' : 'absolute',
                                            bottom: (theme) => theme.spacing(2),
                                            right: (theme) => theme.spacing(2),
                                        }}
                                    >
                                        <CommentIcon />
                                    </Fab>
                                </Tooltip>
                            </Grid>
                        )}
                        <Notification />
                        <Popover
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            // anchorReference="anchorPosition"
                            onClose={handleClose}
                            // anchorPosition={{ top: 1000, left: 1700 }}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            PaperProps={{
                                style: {
                                    borderRadius: 15,
                                },
                            }}
                        >
                            <Contact width={350} />
                        </Popover>
                    </Grid>
                </div>
            </AppProvider>
        );
    };

    return ComponentWithWrapper;
}

export default ComponentWrapper;
