import React from 'react';

// components and helpers
import ComponentWrapper from '../containers/ComponentWrapper';
import SearchDetail from '../components/Search/SearchDetail';
import { Grid } from '@mui/material';

const SearchDetailView: React.FC = () => {
    return (
        <Grid container>
            <Grid item xs={12}>
                <SearchDetail />
            </Grid>
        </Grid>
    );
};

export default ComponentWrapper(SearchDetailView);
