import { createTheme } from '@mui/material/styles';

// set theme overrides for mui datatables package
const mobileDatatableTheme = createTheme({
    MUIDataTableHeadCell: {
        styleOverrides: {
            data: {
                fontWeight: 'bold',
            },
        },
    },
    MuiTableCell: {
        styleOverrides: {
            root: {
                padding: 5,
            },
        },
    },
    MUIDataTable: {
        styleOverrides: {
            liveAnnounce: {
                position: 'relative',
            },
        },
    },
    MUIDataTableBodyCell: {
        styleOverrides: {
            root: {
                overflow: 'auto',
                fontSize: '13px',
                fontWeight: 'bold',
            },
        },
    },
    MUIDataTableToolbar: {
        styleOverrides: {
            root: {
                display: 'flex',
            },
            actions: {
                paddingRight: 10,
            },
        },
    },
    MuiTableRow: {
        styleOverrides: {
            root: {
                '&:hover': {
                    cursor: 'pointer',
                },
            },
        },
    },
});

export default mobileDatatableTheme;
