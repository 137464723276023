import { Reducer } from 'redux';
import { types } from './GlobalActions';
import { IGlobalAction, IGlobalState } from '../../common/model/GlobalModel';

export const globalReducer: Reducer<IGlobalState, IGlobalAction> = (
    state: IGlobalState = {},
    action: IGlobalAction,
) => {
    switch (action.type) {
        case types.GET_M2M_TOKEN_REQUEST: {
            return {
                ...state,
                loading: true,
            };
        }
        case types.GET_M2M_TOKEN_SUCCESS: {
            sessionStorage.setItem('auth_token', action.token || '');
            return {
                ...state,
                loading: false,
                token: action.token,
                duration: action.duration,
            };
        }
        case types.GET_M2M_TOKEN_FAILURE: {
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        }

        default:
            return state;
    }
};
