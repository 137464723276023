import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { PaletteMode } from '@mui/material';
import { getDesignTokens } from './styles/theme';
import { ApolloProvider } from '@apollo/client';
import { CookiesProvider } from 'react-cookie';
import { useCookies } from 'react-cookie';
import { client } from './graphql/context';
import { Provider } from 'react-redux';
import { defaultStore } from './components/Shared/Redux/defaultStore';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import history from './components/Shared/history';
import CssBaseline from '@mui/material/CssBaseline';
import HomeView from './views/HomeView';
import TransactionsView from './views/TransactionsView';
import TxDetailView from './views/SearchDetailView';
import { HocContext } from './common/context/hocContext';
import AdditionalView from './views/AdditionalView';
import FaqView from './views/FaqView';
import NotFoundView from './views/NotFoundView';
import FrameView from './views/FrameView';

export const ColorModeContext = React.createContext({
    toggleColorMode: () => {
        // This is intentional
    },
});

const App = () => {
    const [cookies, setCookies] = useCookies(['hedera_explorer_mode', 'hedera_explorer_unit']);
    const initialModeState: string | (() => string) = cookies.hedera_explorer_mode || 'light';
    const initialUnitState: string | (() => string) = cookies.hedera_explorer_unit || 'true';
    const [mode, setMode] = React.useState<PaletteMode | string>(initialModeState);
    const [unitValue, setUnitValue] = React.useState<string>(initialUnitState);

    const colorMode = React.useMemo(
        () => ({
            // The dark mode switch would invoke this method
            toggleColorMode: () => {
                setMode((prevMode: string) => {
                    const newMode = prevMode === 'light' ? 'dark' : 'light';
                    setCookies('hedera_explorer_mode', newMode, { path: '/', domain: 'hederaexplorer.io' });
                    return newMode;
                });
            },
        }),
        [],
    );

    // Update the theme only if the mode changes
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const theme = React.useMemo(() => createTheme(getDesignTokens(mode)), [mode]);

    const handleCryptoUnitChange = (value: boolean) => {
        setUnitValue(value.toString());
        setCookies('hedera_explorer_unit', value, { path: '/', domain: 'hederaexplorer.io' });
    };

    return (
        <React.StrictMode>
            <CookiesProvider>
                <ApolloProvider client={client}>
                    <Provider store={defaultStore}>
                        <Router history={history}>
                            <HocContext.Provider value={{ handleCryptoUnitChange, unitValue }}>
                                <ColorModeContext.Provider value={colorMode}>
                                    <ThemeProvider theme={theme}>
                                        <CssBaseline />
                                        <Routes>
                                            <Route exact path="/" element={<HomeView />} />
                                            <Route exact path="/transactions" element={<TransactionsView />} />
                                            <Route
                                                exact
                                                path="/transactions/transaction-details/:id"
                                                element={<TxDetailView />}
                                            />
                                            <Route exact path="/search-details/:type/:id" element={<TxDetailView />} />
                                            <Route exact path="/advanced-features" element={<AdditionalView />} />
                                            <Route exact path="/faq" element={<FaqView />} />
                                            <Route exact path="/frame" element={<FrameView />} />
                                            <Route path="*" element={<NotFoundView />} />
                                        </Routes>
                                    </ThemeProvider>
                                </ColorModeContext.Provider>
                            </HocContext.Provider>
                        </Router>
                    </Provider>
                </ApolloProvider>
            </CookiesProvider>
        </React.StrictMode>
    );
};

export default App;
