import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React from 'react';

const loadingComponent = (
    <div
        style={{
            position: 'absolute',
            zIndex: 110,
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        }}
    >
        <Grid container direction={'column'} alignItems={'center'}>
            <Grid item>
                <Typography variant={'body2'} color={'textSecondary'}>
                    Loading...
                </Typography>
            </Grid>
        </Grid>
    </div>
);

export default loadingComponent;
