import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Grid from '@mui/material/Grid';
import loadingComponent from './loadingTextComponent';

export default function SearchTokenSkeletonLoading() {
    return (
        <Grid item xs={12}>
            <Grid container justifyContent={'center'} spacing={2}>
                <Grid item xs={11} md={10}>
                    <div style={{ position: 'relative' }}>
                        {loadingComponent}
                        <Skeleton
                            variant="rectangular"
                            width={'100%'}
                            height={700}
                            style={{ borderRadius: 15 }}
                            animation="wave"
                        />
                    </div>
                </Grid>
            </Grid>
        </Grid>
    );
}
