import logo from '../../../assets/images/logo.png';

interface ThemeConfig {
    logo: any;
    primaryColor: string;
    footerText: string;
    rollingHashDesc: string;
    ownNodesTabLabel: string;
    transactionsTitle: string;
    tokensTitle: string;
}

export const getThemeConfig = (): ThemeConfig => {
    return {
        logo: logo,
        primaryColor: '#4268AE',
        footerText: 'Acoer, Inc.',
        rollingHashDesc:
            'RightsHash™ hashes (encrypts) contents of every transaction. Further, we also link the sequence of each transaction hash back its preceding one, all the way to the first transaction hash. We call this our Rolling Hash.',
        ownNodesTabLabel: 'RightsHash™ Nodes',
        transactionsTitle: 'RightsHash™ System Recent Transactions',
        tokensTitle: 'RightsHash™ System Recent Tokens',
    };
};
