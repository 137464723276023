import { Reducer } from 'redux';
import { types } from './ExplorerAction';
import { IExplorerAction, IExplorerState } from '../../common/model/ExplorerModels';

export const explorerReducer: Reducer<IExplorerState, IExplorerAction> = (
    state: IExplorerState = { nodeData: [] },
    action: IExplorerAction,
) => {
    switch (action.type) {
        case types.GET_MARKET_DATA_REQUEST: {
            return {
                ...state,
                marketDataLoading: true,
            };
        }
        case types.GET_MARKET_DATA_SUCCESS: {
            return {
                ...state,
                marketDataLoading: false,
                marketData: action.payload?.marketData,
            };
        }
        case types.GET_MARKET_DATA_FAILURE: {
            return {
                ...state,
                marketDataLoading: false,
                marketDataError: action.error,
            };
        }
        case types.GET_PRICE_DATA_REQUEST: {
            return {
                ...state,
                priceDataLoading: true,
            };
        }
        case types.GET_PRICE_DATA_SUCCESS: {
            return {
                ...state,
                priceDataLoading: false,
                priceData: action.payload?.priceData,
            };
        }
        case types.GET_PRICE_DATA_FAILURE: {
            return {
                ...state,
                priceDataLoading: false,
                priceDataError: action.error,
            };
        }
        case types.GET_NODE_DATA_REQUEST: {
            return {
                ...state,
                nodeDataLoading: true,
            };
        }
        case types.GET_NODE_DATA_SUCCESS: {
            return {
                ...state,
                nodeDataLoading: false,
                nodeData: [...state.nodeData, ...action.payload?.data],
            };
        }
        case types.GET_NODE_DATA_FAILURE: {
            return {
                ...state,
                nodeDataLoading: false,
                nodeDataError: action.error,
            };
        }
        case types.GET_STAKING_DATA_REQUEST: {
            return {
                ...state,
                stakingDataLoading: true,
            };
        }
        case types.GET_STAKING_DATA_SUCCESS: {
            return {
                ...state,
                stakingDataLoading: false,
                stakingData: action.payload?.stakingData,
            };
        }
        case types.GET_STAKING_DATA_FAILURE: {
            return {
                ...state,
                stakingDataLoading: false,
                stakingDataError: action.error,
            };
        }
        case types.HANDLE_CRYTO_UNIT: {
            return {
                ...state,
                cryptoUnit: action?.payload?.value,
            };
        }
        case types.RESET_EXPLORER: {
            return { nodeData: [] };
        }
        default:
            return state;
    }
};
