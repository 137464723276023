export const dateTime = [
    'consensus',
    'expirationTime',
    'valid_start_timestamp',
    'created_timestamp',
    'modified_timestamp',
    'consensus_timestamp',
    'executed_timestamp',
    'valid_from',
    'valid_to',
    'stake_period_start',
    'staking_period_from',
    'staking_period_to',
];
export const dateDays = ['auto_renew_period'];
export const crypto = [
    'amount',
    'maxFee',
    'gas',
    'charged_tx_fee',
    'max_fee',
    'initial_supply',
    'max_supply',
    'total_supply',
    'kyc_supply',
    'max_stake',
    'min_stake',
    'stake_rewarded',
    'stake_not_rewarded',
    'stake_for_consensus',
    'stake_total',
    'stake',
    'reward_rate_start',
];
export const objectIgnore = [
    'messages',
    'signatures',
    'transfers',
    'links',
    'transactions',
    'token_transfers',
    'timestamp',
    'nft_transfers',
    'service_endpoints',
    'staking_period',
];
export const detailLinks = [
    'entity_id',
    'account',
    'token_id',
    'treasury_account_id',
    'auto_renew_account',
    'creator_account_id',
    'payer_account_id',
    'schedule_id',
    'contract_id',
    'file_id',
    'obtainer_id',
    'proxy_account_id',
    'transaction_id',
    'receiver_account_id',
    'sender_account_id',
    'node',
    'node_account_id',
    'staked_node_id',
    'staked_account_id',
];
export const byteData = ['memo_base64'];
export const copyData = [
    'Key',
    'Supply Key',
    'Admin Key',
    'Public Key',
    'Freeze Key',
    'Fee Schedule Key',
    'Pause Key',
    'Wipe Key',
    'KYC Key',
    'evm_address',
    'bytecode',
    'transaction_hash',
    'public_key',
    'node_cert_hash',
];
export const keys = [
    'Key',
    'Supply Key',
    'Admin Key',
    'Public Key',
    'Freeze Key',
    'Fee Schedule Key',
    'Pause Key',
    'Wipe Key',
    'KYC Key',
    'public_key',
    'node_cert_hash',
];
export const nodeAccounts = [
    '0.0.3',
    '0.0.4',
    '0.0.5',
    '0.0.6',
    '0.0.7',
    '0.0.8',
    '0.0.9',
    '0.0.10',
    '0.0.11',
    '0.0.12',
    '0.0.13',
    '0.0.14',
    '0.0.15',
    '0.0.16',
    '0.0.17',
    '0.0.18',
    '0.0.19',
    '0.0.20',
    '0.0.21',
    '0.0.22',
    '0.0.23',
    '0.0.24',
    '0.0.25',
    '0.0.26',
    '0.0.27',
    '0.0.28',
];
