export const transactionTypes = [
    'ALL',
    'CONSENSUSCREATETOPIC',
    'CONSENSUSDELETETOPIC',
    'CONSENSUSSUBMITMESSAGE',
    'CONSENSUSUPDATETOPIC',
    'CONTRACTCALL',
    'CONTRACTCREATEINSTANCE',
    'CONTRACTDELETEINSTANCE',
    'CONTRACTUPDATEINSTANCE',
    'CRYPTOADDLIVEHASH',
    'CRYPTOAPPROVEALLOWANCE',
    'CRYPTOCREATEACCOUNT',
    'CRYPTODELETE',
    'CRYPTODELETEALLOWANCE',
    'CRYPTODELETELIVEHASH',
    'CRYPTOTRANSFER',
    'CRYPTOUPDATEACCOUNT',
    'ETHEREUMTRANSACTION',
    'FILEAPPEND',
    'FILECREATE',
    'FILEDELETE',
    'FILEUPDATE',
    'FREEZE',
    'NODESTAKEUPDATE',
    'SCHEDULECREATE',
    'SCHEDULEDELETE',
    'SCHEDULESIGN',
    'SYSTEMDELETE',
    'SYSTEMUNDELETE',
    'TOKENASSOCIATE',
    'TOKENBURN',
    'TOKENCREATION',
    'TOKENDELETION',
    'TOKENDISSOCIATE',
    'TOKENFEESCHEDULEUPDATE',
    'TOKENFREEZE',
    'TOKENGRANTKYC',
    'TOKENMINT',
    'TOKENPAUSE',
    'TOKENREVOKEKYC',
    'TOKENUNFREEZE',
    'TOKENUNPAUSE',
    'TOKENUPDATE',
    'TOKENWIPE',
    'UNCHECKEDSUBMIT',
];
