export const faqs = [
    {
        question: 'Why do only the last 24 hours show on the dashboard?',
        answer: 'HederaExplorer dashboard provides the last 24 hour data as a default and free of charge to assist the Hedera community for easy accessibility into their latest transactions, however, the full power of the Hedera ledger is available for your needs. Please visit the Advanced Features to learn more. You can also view all available historical data by using the search bar to view details of accounts, transactions, tokens, contracts, topics and schedules.',
    },
    {
        question: 'Can I see historical data in HederaExplorer?',
        answer: 'Yes, you can view all available historical data by using the search bar to view details of accounts, transactions, tokens, contracts, topics and schedules.',
    },
    {
        question: 'What is available with your advanced features?',
        answer: `Some of our advanced features include historical data extraction, retrieving data from a specific timeframe, advanced reporting and more. Please visit our Advanced Features page for more information.`,
    },
    {
        question: 'Why is the the amount shown in tinybar instead of hbar?',
        answer: 'We provide you the ability to view both units. This toggle bar is located in the top right of the page to change between the two.',
    },
    {
        question: 'How come every tinybar is not converted to HBAR when toggled?',
        answer: 'Our HederaExplorer uses the Hedera SDK to make the conversions. If the amount of tinybars are too small to convert to HBAR, it remains as tinybar for readability.',
    },
    {
        question: 'How do I know why a result failed?',
        answer: 'To view the reason why a result failed, hover over the failed X image to read the tooltip, or you can click on the transaction to see further details.',
    },
    {
        question: 'How frequently does the market data update?',
        answer: 'This data refreshes every 30 seconds.',
    },
    {
        question: 'Does the Hedera Node map provide a general geographic location of nodes that are running?',
        answer: 'The Hedera Council Node map displays node locations based on the status information found here',
    },
];
