import React from 'react';

// components and helpers
import ComponentWrapper from '../containers/ComponentWrapper';
import TransactionsList from '../components/Transactions/TransactionsList';

const TransactionsView: React.FC = () => {
    return <TransactionsList />;
};

export default ComponentWrapper(TransactionsView);
