import React, { useEffect, createContext, useState, PropsWithChildren } from 'react';
import { useLocation } from 'react-router-dom';

// redux
import { bindActionCreators, Dispatch } from 'redux';
import { connect, RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { IExplorer } from '../components/Shared/Redux';
import { IAppContextProps, IExplorerProps, INodeProps } from '../common/model/ExplorerModels';
import { IExplorerAction } from '../common/model/ExplorerModels';
import { explorerActions } from '../components/Explorer/ExplorerAction';
import { tokenActions } from '../components/Shared/GlobalActions';
import { useLazyQuery } from '@apollo/client';
import { NODE_QUERY } from '../graphql/gqlQueries';

export const AppContext = createContext<IAppContextProps>(undefined!);
const AppProviderWrapper: React.FC<PropsWithChildren<IExplorerProps>> = ({ children }) => {
    const [currentPath, setCurrentPath] = useState<string>('');
    const [hederaNodeData, setHederaNodeData] = useState<any[]>();
    const [hederaNodeCoordinates, setHederaNodeCoordinates] = useState<(string | number)[][]>();
    const nodeData = useSelector((state: RootStateOrAny) => state.explorer.nodeData);
    const location = useLocation();
    const dispatch = useDispatch();

    useEffect(() => {
        if (currentPath !== location) {
            setCurrentPath(location.pathname);
        }
    }, [location]);

    const [getNodeData, { loading: nodeDataLoading }] = useLazyQuery(NODE_QUERY, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'cache-first',
        nextFetchPolicy: 'cache-first',
        onCompleted: (data) => {
            const coordinates: any[number] = [];
            const updatedNodeData: any[] = [];
            data.getNodes.map((node: INodeProps, i: number) => {
                //todo implement reduce?
                const accountTxMatch = data.getNodeStats.filter((x: any) => x.nodeAccountId === node.accountId);
                const nodeAccountMatch = nodeData.filter((x: any) => x.node_account_id === node.accountId);
                const txNumber = accountTxMatch.length ? accountTxMatch[0].numberOfTransactions : 0;
                const stakingNumber = nodeAccountMatch && nodeAccountMatch.length && nodeAccountMatch[0].stake;
                const maxStake = nodeAccountMatch && nodeAccountMatch.length && nodeAccountMatch[0].max_stake;
                const stakingRewards =
                    nodeAccountMatch && nodeAccountMatch.length && nodeAccountMatch[0].stake_rewarded;
                const stakingNotNumber =
                    nodeAccountMatch && nodeAccountMatch.length && nodeAccountMatch[0].stake_not_rewarded;
                const nodes: any[number] = [];
                nodes.push(node.latitude);
                nodes.push(node.longitude);
                // nodes.push('');
                nodes.push(node.organization);
                nodes.push(`Transactions: ${Intl.NumberFormat('en-US').format(txNumber as number)}`);
                updatedNodeData.push({
                    ...data.getNodes[i],
                    numTransactions: txNumber,
                    stake: stakingNumber,
                    stake_not_rewarded: stakingNotNumber,
                    stake_rewarded: stakingRewards,
                    max_stake: maxStake,
                });
                coordinates.push(nodes);
            });
            setHederaNodeData(updatedNodeData);
            setHederaNodeCoordinates(coordinates);
        },
    });

    useEffect(() => {
        dispatch(explorerActions?.getNodeData('node.id=lt:10'));
        dispatch(explorerActions?.getNodeData('node.id=gt:9'));
        dispatch(explorerActions?.getNodeData('node.id=gt:19'));
    }, []);

    useEffect(() => {
        const url = window.location.host;
        const env = url?.includes('testnet') || url?.includes('localhost') ? 'test' : 'main';

        if (nodeData && env === 'main' ? nodeData.length > 20 : nodeData.length > 6) {
            getNodeData();
        }
    }, [nodeData]);

    return (
        <AppContext.Provider
            value={{
                currentPath,
                hederaNodeData,
                hederaNodeCoordinates,
            }}
        >
            {children}
        </AppContext.Provider>
    );
};

function mapStateToProps(store: IExplorer) {
    return {
        m2mToken: store.global?.token,
        m2mAuthDuration: store.global?.duration,
    };
}

function mapDispatchToProps(dispatch: Dispatch<IExplorerAction>) {
    return {
        actions: bindActionCreators(explorerActions, dispatch),
        globalActions: bindActionCreators(tokenActions, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AppProviderWrapper);
