import React, { useContext, useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import { ThemeProvider } from '@mui/material';
import MUIDataTable from 'mui-datatables';
import {
    balanceColumns,
    messageColumns,
    nftColumns,
    nodeServiceColumns,
    signatureColumns,
    tokenColumns,
    txColumns,
} from './helpers/detailTableColumns';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Paper from '@mui/material/Paper';
import datatableTheme from '../../assets/styling/DatatableStyles';
import mobileDatatableTheme from '../../assets/styling/MobileDatatableStyles';
import { HocContext } from '../../common/context/hocContext';
import Loader from './helpers/Loader';

// components and helpers

interface ISearchDetailTableSectionProps {
    type: string;
    searchValue: string;
    links?: any;
    setupFetchData: (searchValue: string, pageLimit: number, type: string, nextUrl?: string) => void;
    clearLocal: () => void;
    data?: any;
    title: string;
    loading?: boolean;
}
const SearchDetailTableSection: React.FC<ISearchDetailTableSectionProps> = ({
    type,
    links,
    searchValue,
    setupFetchData,
    clearLocal,
    data,
    title,
    loading,
}) => {
    const [page, setPage] = useState<number>(0);
    const [pageLimit, setPageLimit] = useState<number>(10);
    const [previousList, setPreviousList] = useState<any>();
    const [currentList, setCurrentList] = useState<any>();
    const [currentNext, setCurrentNext] = useState<any>();
    const [previousNext, setPreviousNext] = useState<any>();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));
    const navigate = useNavigate();
    const tableTheme = {
        ...theme,
        components: matches ? datatableTheme : mobileDatatableTheme,
    };

    useEffect(() => {
        if (data) {
            setCurrentList(data);
        }
        if (links) {
            setCurrentNext(links && links.next);
        }
    }, [data, links]);

    const handleTableChange = (action: string, tableState: any, type: string, nextLink: string) => {
        if (action !== 'propsUpdate' && action === 'changePage') {
            const nextPage = tableState.page;
            if (nextPage > page && nextLink) {
                const nextUrl = nextLink.split('desc').pop();
                setPreviousList({
                    ...previousList,
                    [page]: currentList,
                });
                setPreviousNext({
                    ...previousNext,
                    [page]: currentNext,
                });
                setupFetchData(searchValue as string, pageLimit, type, nextUrl);
                setPage(nextPage);
            } else {
                if (page - 1 > 0) {
                    setCurrentList(previousList[page - 1]);
                    setCurrentNext(previousNext[page - 1]);
                    setPage(page - 1);
                } else {
                    setPreviousList({});
                    setPreviousNext({});
                    setupFetchData(searchValue as string, pageLimit, type);
                    setPage(0);
                }
            }
        }
    };

    const handleRowClick = (data: any[]) => {
        let value = data[0];
        if (type === 'balances') {
            navigate(`/search-details/account/${value}`);
        } else if (type === 'messages' || type === 'nfts') {
            value = data[1];
            navigate(`/search-details/account/${value}`);
        } else if (type === 'accountTransaction') {
            navigate(`/search-details/transaction/${value}`);
        } else {
            navigate(`/search-details/${type}/${value}`);
        }
        clearLocal();
    };
    const options = {
        onRowClick: type !== 'signature' && type !== 'nodeServiceEndpoints' && handleRowClick,
        elevation: 0,
        download: false,
        filter: false,
        pagination: true,
        print: false,
        search: false,
        selectableRowsHeader: false,
        selectableRowsHideCheckboxes: true,
        viewColumns: false,
        rowsPerPage: pageLimit,
        rowsPerPageOptions: [10],
        responsive: 'simple',
        filterType: 'multiselect',
        serverSide: type !== 'token',
        // tableBodyHeight: matches && rowCount && height,
        textLabels: {
            body: {
                noMatch: (
                    <Typography style={{ fontFamily: 'Inter, sans-serif', fontSize: 12 }}>
                        Sorry, no matching records found
                    </Typography>
                ),
            },
        },
        page: type !== 'token' ? page : 0,
        count:
            type === 'token'
                ? data.length
                : currentNext
                ? 1000000
                : page > 0 && previousNext[page - 1] && page > 0
                ? 20
                : 10,
        onTableChange: (action: string, tableState: any) =>
            type !== 'token' && handleTableChange(action, tableState, type, currentNext),
    };

    const loadingComponent = (
        <div
            style={{
                position: 'absolute',
                zIndex: 110,
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                background: `${theme.palette.background.paper}80`,
            }}
        >
            <Loader size={60} color={theme.palette.primary.main} />
        </div>
    );
    return (
        <Paper
            elevation={0}
            style={{ padding: matches ? 36 : 15, borderRadius: 15 }}
            id={type !== 'token' ? 'serverList' : 'tokenList'}
        >
            <ThemeProvider theme={tableTheme}>
                <div style={{ position: 'relative' }}>
                    {(loading || !data) && loadingComponent}
                    <MUIDataTable
                        data={currentList}
                        columns={
                            type === 'transaction' || type === 'accountTransaction'
                                ? txColumns(matches)
                                : type === 'token'
                                ? tokenColumns(matches)
                                : type === 'signature'
                                ? signatureColumns(matches)
                                : type === 'nfts'
                                ? nftColumns(matches)
                                : type === 'balances'
                                ? balanceColumns(matches)
                                : type === 'nodeServiceEndpoints'
                                ? nodeServiceColumns(matches)
                                : messageColumns(matches)
                        }
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        options={options}
                        title={
                            <Typography
                                variant={'h6'}
                                style={{ fontWeight: 'bold', fontFamily: 'Inter, sans-serif' }}
                                color={'secondary'}
                            >
                                {title}
                            </Typography>
                        }
                    />
                </div>
            </ThemeProvider>
        </Paper>
    );
};

export default SearchDetailTableSection;
