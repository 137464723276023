import { gql } from '@apollo/client';

export const TRANSACTION_SUBSCRIPTION = gql`
    subscription {
        nbOfTransactions
    }
`;

export const TRANSACTION_DETAILS_SUBSCRIPTION = gql`
    subscription {
        latestTransactions {
            consensus
            type
            node
            result
            amount
            id
        }
    }
`;
