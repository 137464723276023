import * as React from 'react';
import { useContext } from 'react';

// components and helpers
import { LineChart } from '@acoer/ac-js-lib-charts';
import { HocContext } from '../../common/context/hocContext';
import utils from '../../common/utils/utils';

// mui
import Paper from '@mui/material/Paper';
import { useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Hbar, HbarUnit } from '@hashgraph/sdk';

export interface TxLineChartProps {
    title: string;
    data: any;
}

const TxLineChart: React.FC<TxLineChartProps> = ({ title = '', data }) => {
    const { unitValue } = useContext(HocContext);
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));

    return (
        <Paper
            style={{ height: 270, borderRadius: 15, padding: matches ? '15px 10px 30px 10px' : '15px 5px 50px 5px' }}
            elevation={0}
        >
            <LineChart
                data={data}
                onDownloadCsv={() => console.log('download')}
                chartTitle={title}
                primaryText={theme.palette.mode === 'light' ? theme.palette.text.primary : '#D3D3D3'}
                customClasses={{ fontWeight: 'bold', fontFamily: 'Inter, sans-serif' }}
                customVariant={'h6'}
                showToolbar={matches}
                colourScheme={['#3E8BFF']}
                yFormatter={(value) =>
                    utils.convertToInternationalCurrencySystem(
                        unitValue === 'true'
                            ? value
                            : parseInt(Hbar.from(parseInt(value), HbarUnit.Tinybar)?.toLocaleString().split(' ')[0]),
                    )
                }
                chartTheme={theme.palette.mode}
                tooltipFormatter={(value) =>
                    Intl.NumberFormat('en-US').format(
                        Math.round(
                            unitValue === 'true'
                                ? value
                                : parseInt(
                                      Hbar.from(parseInt(value), HbarUnit.Tinybar)?.toLocaleString().split(' ')[0],
                                  ),
                        ),
                    )
                }
                tickAmount={12}
            />
        </Paper>
    );
};

export default TxLineChart;
