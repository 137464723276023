import React, { useState } from 'react';
import axios from 'axios';

// mui
import Paper from '@mui/material/Paper';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import history from '../Shared/history';
import SearchIcon from '@mui/icons-material/Search';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import utils from '../../common/utils/utils';
import LinearProgress from '@mui/material/LinearProgress';

interface ISearchbarProps {
    invalid: boolean;
    setInvalid: (value: boolean) => void;
}

const Searchbar: React.FC<ISearchbarProps> = ({ invalid, setInvalid }) => {
    const [searchValue, setSearchValue] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));

    const handleSearch = (ev: React.KeyboardEvent<HTMLDivElement>) => {
        if (ev.key === 'Enter' && searchValue) {
            ev.preventDefault();
            handleSearchValue();
        } else {
            return null;
        }
    };

    const handleSearchValue = () => {
        const API_ROOT = process.env.REACT_APP_OBJECT_TYPE;
        let value = searchValue;
        setLoading(true);
        if (searchValue.includes('@')) {
            const formattedTx = utils.txToApi(searchValue);
            value = formattedTx;
        }
        axios
            .get(`${API_ROOT}/${value}`)
            .then((res) => {
                if (res.data.found) {
                    history.push(`/search-details/${res.data.type}/${value}`);
                    setSearchValue('');
                    setLoading(false);
                } else {
                    setInvalid(true);
                    setLoading(false);
                }
            })
            .catch((error) => {
                console.log('Search Error', error);
                setInvalid(true);
                setLoading(false);
            });
    };

    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const value = e.target.value;
        setSearchValue(value);
        setInvalid(false);
    };

    return (
        <Paper
            component="form"
            sx={{
                '& .MuiTextField-root': { width: '100%' },
            }}
            noValidate
            autoComplete="off"
            style={{ borderRadius: 10 }}
        >
            <TextField
                id="outlined-search"
                placeholder={matches ? 'Search accounts, transactions, tokens, contracts, topics and schedules' : ''}
                type="search"
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    ),
                    endAdornment: (
                        <InputAdornment position="end">
                            <Button
                                variant={'contained'}
                                color={'primary'}
                                style={{ borderRadius: 50, textTransform: 'none', fontWeight: 'bold' }}
                                disableElevation
                                onClick={handleSearchValue}
                                disabled={!searchValue}
                            >
                                Search
                            </Button>
                        </InputAdornment>
                    ),
                }}
                value={searchValue}
                onChange={(e) => handleChange(e)}
                onKeyPress={(ev) => handleSearch(ev)}
            />
            {loading && <LinearProgress color="primary" />}
        </Paper>
    );
};

export default Searchbar;
