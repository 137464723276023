import React from 'react';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';

class CustomToolbar extends React.Component {
    render() {
        return (
            <React.Fragment>
                <Link to={'/transactions'} style={{ textDecoration: 'none' }}>
                    <Button
                        variant={'contained'}
                        color={'primary'}
                        size={'small'}
                        style={{ borderRadius: 50, textTransform: 'none', fontWeight: 'bold', zIndex: 999999 }}
                        disableElevation
                    >
                        View All
                    </Button>
                </Link>
            </React.Fragment>
        );
    }
}

export default CustomToolbar;
