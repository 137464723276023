import React from 'react';

// components and helpers
import ComponentWrapper from '../containers/ComponentWrapper';
import FaqList from '../components/FAQ/FaqList';

const AdditionalView: React.FC = () => {
    return <FaqList />;
};

export default ComponentWrapper(AdditionalView);
