import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Grid from '@mui/material/Grid';
import loadingComponent from './loadingTextComponent';

interface TxDayChartsSkeletonLoadingProps {
    height?: number;
}
const TxDayChartsSkeletonLoading: React.FC<TxDayChartsSkeletonLoadingProps> = ({ height }) => {
    return (
        <Grid item xs={12}>
            <div style={{ position: 'relative' }}>
                {loadingComponent}
                <Skeleton
                    variant={'rectangular'}
                    width={'100%'}
                    height={height || 270}
                    style={{ borderRadius: 15 }}
                    animation={'wave'}
                />
            </div>
        </Grid>
    );
};

export default TxDayChartsSkeletonLoading;
