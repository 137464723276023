import { Reducer } from 'redux';
import { types } from './SearchActions';
import { IExplorerAction, IExplorerState } from '../../common/model/ExplorerModels';

export const searchReducer: Reducer<IExplorerState, IExplorerAction> = (
    state: IExplorerState = {},
    action: IExplorerAction,
) => {
    switch (action.type) {
        case types.GET_SEARCH_DETAILS_REQUEST: {
            if (action.payload?.type === 'balances' || action.payload?.type === 'nfts') {
                return {
                    ...state,
                    [`${action.payload?.type}DetailsLoading`]: true,
                    searchDetailsDataError: null,
                    nodeDetails: null,
                };
            } else {
                return {
                    ...state,
                    detailsLoading: true,
                    searchDetailsDataError: null,
                    nodeDetails: null,
                };
            }
        }
        case types.GET_SEARCH_DETAILS_SUCCESS: {
            if (action.payload?.type === 'balances' || action.payload?.type === 'nfts') {
                return {
                    ...state,
                    [`${action.payload?.type}DetailsLoading`]: false,
                    [`${action.payload?.type}Details`]: action.payload?.data,
                };
            } else {
                return {
                    ...state,
                    detailsLoading: false,
                    [`${action.payload?.type}Details`]: action.payload?.data,
                };
            }
        }
        case types.GET_SEARCH_DETAILS_FAILURE: {
            if (action.payload?.type === 'balances' || action.payload?.type === 'nfts') {
                return {
                    ...state,
                    [`${action.payload?.type}DetailsLoading`]: false,
                    searchDetailsDataError: action.error,
                };
            } else {
                return {
                    ...state,
                    detailsLoading: false,
                    searchDetailsDataError: action.error,
                };
            }
        }
        case types.GET_SEARCH_DETAILS_TRANSACTIONS_REQUEST: {
            return {
                ...state,
                accountTransactionDetailsLoading: true,
                nodeDetails: null,
            };
        }
        case types.GET_SEARCH_DETAILS_TRANSACTIONS_SUCCESS: {
            return {
                ...state,
                accountTransactionDetailsLoading: false,
                accountTransactionDetails: action.payload?.data.transactions,
                accountTransactionDetailsLink: action.payload?.data.links,
            };
        }
        case types.GET_SEARCH_DETAILS_TOKENS_SUCCESS: {
            return {
                ...state,
                accountTokenDetailsLoading: false,
                accountTokenDetails: action.payload?.data,
            };
        }
        case types.GET_TOKEN_DETAILS_REQUEST: {
            return {
                ...state,
                tokenDetailsLoading: true,
            };
        }
        case types.GET_TOKEN_DETAILS_SUCCESS: {
            let detailData: any;
            let key = '';
            if (action.payload?.type === 'account') {
                detailData = [...state.accountTokenDetails];
                key = 'accountTokenDetails';
                let symbol: string;
                if (detailData) {
                    action.payload?.data.map((tokenData: any, i: number) => {
                        detailData.map((item: any, i: number) => {
                            if (item.token_id === tokenData.data.token_id) {
                                symbol = tokenData.data.symbol;
                                detailData[i]['symbol'] = symbol;
                            }
                        });
                    });
                }
            } else if (action.payload?.type === 'transaction') {
                detailData = { ...state.transactionDetails };
                key = 'transactionDetails';
                let symbol: string;
                action.payload?.data.map((tokenData: any, i: number) => {
                    if (detailData.transactions) {
                        detailData.transactions[0].token_transfers.map((item: any, i: number) => {
                            if (item.token_id === tokenData.data.token_id) {
                                symbol = tokenData.data.symbol;
                                detailData.transactions[0].token_transfers[i]['symbol'] = symbol;
                            }
                        });
                    }
                });
            } else if (action.payload?.type === 'nfts') {
                detailData = { ...state.transactionDetails };
                let symbol: string;
                if (detailData.transactions) {
                    action.payload?.data.map((tokenData: any, i: number) => {
                        detailData.transactions[0].nft_transfers.map((item: any, i: number) => {
                            if (item.token_id === tokenData.data.token_id) {
                                symbol = tokenData.data.symbol;
                                detailData.transactions[0].nft_transfers[i]['symbol'] = symbol;
                            }
                        });
                    });
                }
            }

            return {
                ...state,
                [key]: detailData,
                tokenDetailsLoading: true,
            };
        }
        case types.GET_NODE_DETAILS_REQUEST: {
            return {
                ...state,
                nodeDetailLoading: true,
            };
        }
        case types.GET_NODE_DETAILS_SUCCESS: {
            return {
                ...state,
                nodeDetailLoading: false,
                nodeDetails: action.payload?.nodeDetails,
            };
        }
        case types.GET_NODE_DETAILS_FAILURE: {
            return {
                ...state,
                nodeDetailLoading: false,
                nodeDetailError: action.error,
            };
        }
        case types.GET_TX_TOPIC_MESSAGE_REQUEST: {
            return {
                ...state,
                txTopicMessageLoading: true,
            };
        }
        case types.GET_TX_TOPIC_MESSAGE_SUCCESS: {
            return {
                ...state,
                txTopicMessageLoading: false,
                txTopicMessage: action.payload?.txTopicMessage,
            };
        }
        case types.GET_TX_TOPIC_MESSAGE_FAILURE: {
            return {
                ...state,
                txTopicMessageLoading: false,
                txTopicMessageError: action.error,
            };
        }
        case types.RESET_SEARCH_DETAILS: {
            return {};
        }
        default:
            return state;
    }
};
