import React from 'react';

// components and helpers
import ComponentWrapper from '../containers/ComponentWrapper';
import AdditionalFeatures from '../components/AdditionalFeatures/AdditionalFeatures';

const AdditionalView: React.FC = () => {
    return <AdditionalFeatures />;
};

export default ComponentWrapper(AdditionalView);
