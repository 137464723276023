import { createTheme } from '@mui/material/styles';

// set theme overrides for mui datatables package
const datatableTheme = createTheme({
    MUIDataTableHeadCell: {
        styleOverrides: {
            root: {
                fontWeight: 'bolder',
            },
        },
    },
    MuiTableCell: {
        styleOverrides: {
            root: {
                padding: 5,
            },
        },
    },
    MUIDataTable: {
        styleOverrides: {
            liveAnnounce: {
                position: 'relative',
            },
        },
    },
    MUIDataTableBodyCell: {
        styleOverrides: {
            root: {
                display: 'in-line',
                overflow: 'hidden',
                textOverflow: 'ellipses',
                whiteSpace: 'nowrap',
            },
        },
    },
    MuiTableRow: {
        styleOverrides: {
            root: {
                '&:hover': {
                    cursor: 'pointer',
                },
            },
        },
    },
});

export default datatableTheme;
