import * as React from 'react';
import { useCallback, useEffect } from 'react';

// component and helper imports
import { ReactComponent as MarketImage } from '../../assets/images/overview_market.svg';
import { ReactComponent as VolumeImage } from '../../assets/images/overview_volume.svg';
import { ReactComponent as TxImage } from '../../assets/images/overview_tx.svg';
import { useSubscription } from '@apollo/client';
import { TRANSACTION_SUBSCRIPTION } from '../../graphql/gqlSubscriptions';
import { explorerActions } from '../Explorer/ExplorerAction';

// mui
import Paper from '@mui/material/Paper';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Chip from '@mui/material/Chip';
import { useTheme } from '@mui/material';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import MarketSkeleton from '../Shared/LoadingSkeletons/MarketSkeleton';

const HederaOverview: React.FC = () => {
    const { data: txSubData } = useSubscription(TRANSACTION_SUBSCRIPTION);
    const dispatch = useDispatch();
    const marketData = useSelector((state: RootStateOrAny) => state.explorer.marketData);
    const theme = useTheme();

    useEffect(() => {
        fetchMarketData();
    }, []);

    // fetch latest data every 3 seconds
    useEffect(() => {
        const fetchMarketTimer = setInterval(() => {
            fetchMarketData();
        }, 100000);
        return () => {
            clearInterval(fetchMarketTimer);
            dispatch(explorerActions?.resetExplorer());
        };
    }, [explorerActions]);

    const fetchMarketData = useCallback(() => {
        dispatch(explorerActions?.getMarketData());
        dispatch(explorerActions?.getStakingData());
    }, []);

    if (!marketData || !txSubData) return <MarketSkeleton />;
    return (
        <Grid container spacing={1}>
            <Grid item xs={12} md={7} sm={6}>
                <Paper style={{ borderRadius: 15, height: 270, padding: 30 }} elevation={0}>
                    <Grid container justifyContent={'space-between'}>
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Grid container spacing={1} alignItems={'center'}>
                                        <Grid item>
                                            <Typography
                                                variant={'h5'}
                                                style={{ fontWeight: 'bold', fontFamily: 'Inter, sans-serif' }}
                                            >
                                                Current Price
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant={'caption'} style={{ fontFamily: 'Inter, sans-serif' }}>
                                                (USD)
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container alignItems={'center'} spacing={2} alignContent={'center'}>
                                        <Grid item>
                                            <Typography variant={'h2'} style={{ fontFamily: 'Inter, sans-serif' }}>
                                                $
                                                {marketData &&
                                                    marketData.market_data &&
                                                    marketData.market_data.current_price &&
                                                    marketData.market_data.current_price.usd &&
                                                    marketData.market_data.current_price.usd.toFixed(4)}
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Grid container justifyContent={'space-between'}>
                                                {marketData.market_data &&
                                                    marketData.market_data.price_change_percentage_1h_in_currency &&
                                                    marketData.market_data.price_change_percentage_1h_in_currency
                                                        .usd && (
                                                        <Grid item>
                                                            <Grid container spacing={1}>
                                                                <Chip
                                                                    icon={
                                                                        Math.sign(
                                                                            parseInt(
                                                                                marketData.market_data
                                                                                    .price_change_percentage_1h_in_currency
                                                                                    .usd,
                                                                            ),
                                                                        ) ? (
                                                                            <TrendingUpIcon />
                                                                        ) : (
                                                                            <TrendingDownIcon />
                                                                        )
                                                                    }
                                                                    label={
                                                                        <Typography
                                                                            style={{ fontFamily: 'Inter, sans-serif' }}
                                                                        >
                                                                            {marketData.market_data &&
                                                                                marketData.market_data
                                                                                    .price_change_percentage_1h_in_currency &&
                                                                                marketData.market_data
                                                                                    .price_change_percentage_1h_in_currency
                                                                                    .usd &&
                                                                                marketData.market_data.price_change_percentage_1h_in_currency.usd
                                                                                    .toFixed(3)
                                                                                    .replace('-', '')}
                                                                            %
                                                                        </Typography>
                                                                    }
                                                                    variant="filled"
                                                                    style={{
                                                                        backgroundColor: Math.sign(
                                                                            parseInt(
                                                                                marketData.market_data
                                                                                    .price_change_percentage_1h_in_currency
                                                                                    .usd,
                                                                            ),
                                                                        )
                                                                            ? '#2FA391'
                                                                            : undefined,
                                                                    }}
                                                                    color={
                                                                        Math.sign(
                                                                            parseInt(
                                                                                marketData.market_data
                                                                                    .price_change_percentage_1h_in_currency
                                                                                    .usd,
                                                                            ),
                                                                        )
                                                                            ? 'primary'
                                                                            : 'error'
                                                                    }
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    )}
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Grid container>
                                                <Grid item>
                                                    <Typography
                                                        variant={'caption'}
                                                        style={{ color: 'gray', fontFamily: 'Inter, sans-serif' }}
                                                    >{`Updated ${new Date(
                                                        marketData.market_data.last_updated,
                                                    ).toLocaleTimeString('en-US')}`}</Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography
                                                        variant={'caption'}
                                                        style={{ color: 'gray', fontFamily: 'Inter, sans-serif' }}
                                                    >
                                                        * Price change in the last hour
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography
                                                        variant={'caption'}
                                                        style={{ color: 'gray', fontFamily: 'Inter, sans-serif' }}
                                                    >
                                                        ** Market data courtesy of CoinGecko
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            <Grid item xs={12} md={5} sm={6}>
                <Paper style={{ borderRadius: 15, height: 270, padding: 10 }} elevation={0}>
                    <Grid item xs={12}>
                        <Grid container>
                            <List dense sx={{ width: '100%', maxWidth: 360 }}>
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <MarketImage />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={
                                            <Typography
                                                variant={'caption'}
                                                style={{ color: 'gray', fontSize: 10, fontFamily: 'Inter, sans-serif' }}
                                            >
                                                MARKET CAP (USD)
                                            </Typography>
                                        }
                                        secondary={
                                            <Typography variant={'h6'} style={{ fontFamily: 'Inter, sans-serif' }}>
                                                $
                                                {marketData.market_data.market_cap.usd
                                                    .toString()
                                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                            </Typography>
                                        }
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <VolumeImage />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={
                                            <Typography
                                                variant={'caption'}
                                                style={{ color: 'gray', fontSize: 10, fontFamily: 'Inter, sans-serif' }}
                                            >
                                                TOTAL VOLUME (USD)
                                            </Typography>
                                        }
                                        secondary={
                                            <Typography variant={'h6'} style={{ fontFamily: 'Inter, sans-serif' }}>
                                                $
                                                {marketData.market_data.total_volume.usd
                                                    .toString()
                                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                            </Typography>
                                        }
                                    />
                                </ListItem>
                                {/* <ListItem>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <TxImage />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={
                                            <Typography
                                                variant={'caption'}
                                                style={{ color: 'gray', fontSize: 10, fontFamily: 'Inter, sans-serif' }}
                                            >
                                                # OF TX IN LAST 24H
                                            </Typography>
                                        }
                                        secondary={
                                            <Grid container alignItems={'center'} spacing={1}>
                                                <Grid item>
                                                    <Typography
                                                        variant={'h6'}
                                                        style={{
                                                            fontFamily: 'Inter, sans-serif',
                                                            color: theme.palette.mode === 'light' ? '#333750' : '#fff',
                                                        }}
                                                    >
                                                        {txSubData &&
                                                            txSubData.nbOfTransactions
                                                                .toString()
                                                                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                    </Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography
                                                        variant={'caption'}
                                                        style={{ fontFamily: 'Inter, sans-serif' }}
                                                    >
                                                        ({txSubData && (txSubData.nbOfTransactions / 86400).toFixed(2)}{' '}
                                                        tx/s)
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        }
                                    />
                                </ListItem> */}
                            </List>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    );
};

export default HederaOverview;
