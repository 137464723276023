import React from 'react';

// components and helpers
import acoerLogo from '../../assets/images/acoerLogo.png';

// mui
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TwitterIcon from '@mui/icons-material/Twitter';
import LanguageIcon from '@mui/icons-material/Language';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const Feedback: React.FC = () => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));

    return (
        <Paper elevation={0} style={{ padding: 15, borderRadius: 15, height: '100%' }}>
            <Grid container spacing={2} alignItems={'center'}>
                {!matches && (
                    <Grid item xs={11}>
                        <Typography variant={'body1'} style={{ fontWeight: 'bold', fontFamily: 'Inter, sans-serif' }}>
                            Your feedback is valuable to us!
                        </Typography>
                    </Grid>
                )}
                <Grid item md={12} xs={12}>
                    {matches && (
                        <Typography
                            variant={'h6'}
                            style={{ fontWeight: 'bold', fontFamily: 'Inter, sans-serif' }}
                            color={'primary'}
                        >
                            Your feedback is valuable to us!
                        </Typography>
                    )}
                    <Typography variant={'caption'}>
                        We value the input and feedback of our community. Your thoughts and suggestions help us improve
                        and provide the best possible service. We encourage you to reach out to us with any feedback or
                        concerns you may have. Your input is important to us and helps shape the future of our Explorer.
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container justifyContent={'space-evenly'} style={{ paddingTop: 8 }}>
                        <Grid item>
                            <a
                                href={'https://twitter.com/acoerco'}
                                target={'_blank'}
                                rel="noreferrer"
                                style={{ textDecoration: 'none' }}
                            >
                                <Button variant="outlined" startIcon={<TwitterIcon />} color={'primary'} size={'small'}>
                                    @acoerco
                                </Button>
                            </a>
                        </Grid>
                        <Grid item>
                            <a
                                href={'https://www.acoer.com/contact'}
                                target={'_blank'}
                                rel="noreferrer"
                                style={{ textDecoration: 'none' }}
                            >
                                <Button
                                    variant="outlined"
                                    startIcon={<LanguageIcon />}
                                    color={'primary'}
                                    size={'small'}
                                >
                                    Contact Us
                                </Button>
                            </a>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container justifyContent={'flex-end'} alignItems={'center'} spacing={1}>
                        <Grid item>
                            <Typography variant={'caption'}>Developed and managed by </Typography>
                        </Grid>
                        <Grid item>
                            <a
                                href={'https://www.acoer.com'}
                                target={'_blank'}
                                rel="noreferrer"
                                style={{ textDecoration: 'none' }}
                            >
                                <img src={acoerLogo} alt={'Acoer Logo'} height={15} style={{ marginTop: 8 }} />
                            </a>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default Feedback;
