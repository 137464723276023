import React, { useState } from 'react';

// mui
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';

export interface ContactProps {
    width?: any;
}
const Contact: React.FC<ContactProps> = ({ width }) => {
    const theme = useTheme();
    const [contactInfo, setContactForm] = useState({
        name: '',
        email: '',
        phone: '',
        message: '',
    });
    const disabled = !contactInfo.name || !contactInfo.email || !contactInfo.phone || !contactInfo.message;

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setContactForm({
            ...contactInfo,
            [event.target.name]: event.target.value,
        });
    };

    return (
        <Paper
            elevation={0}
            style={{ padding: 15, width: width, backgroundColor: theme.palette.mode === 'dark' ? '#131A1C' : '#fff' }}
        >
            <form action="https://formspree.io/f/xdobwbjl" method="POST">
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography
                            variant={'h5'}
                            color={'secondary'}
                            style={{ fontWeight: 'bold', fontFamily: 'Inter, sans-serif' }}
                        >
                            Contact Us
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="outlined-basic"
                            label="Name"
                            name={'name'}
                            variant="outlined"
                            style={{ width: '100%' }}
                            placeholder="Your Name"
                            value={contactInfo.name}
                            onChange={handleChange}
                            size={'small'}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="outlined-basic"
                            label="Email"
                            name={'email'}
                            variant="outlined"
                            style={{ width: '100%' }}
                            placeholder="you@company.com"
                            value={contactInfo.email}
                            onChange={handleChange}
                            size={'small'}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="outlined-basic"
                            label="Phone Number"
                            name={'phone'}
                            variant="outlined"
                            style={{ width: '100%' }}
                            placeholder="1 (555) 000-0000"
                            value={contactInfo.phone}
                            onChange={handleChange}
                            size={'small'}
                        />
                    </Grid>
                    {/*<Grid item xs={12}>*/}
                    {/*    <TextField*/}
                    {/*        id="outlined-basic"*/}
                    {/*        label="Company"*/}
                    {/*        name={'company'}*/}
                    {/*        variant="outlined"*/}
                    {/*        style={{ width: '100%' }}*/}
                    {/*        placeholder="Your Company"*/}
                    {/*        value={contactInfo.company}*/}
                    {/*        onChange={handleChange}*/}
                    {/*        size={'small'}*/}
                    {/*    />*/}
                    {/*</Grid>*/}
                    <Grid item xs={12}>
                        <TextField
                            id="outlined-basic"
                            label="Message"
                            name={'message'}
                            variant="outlined"
                            style={{ width: '100%' }}
                            rows={4}
                            multiline
                            placeholder="Enter your message here..."
                            onChange={handleChange}
                            value={contactInfo.message}
                            size={'small'}
                        />
                    </Grid>
                    <Grid container justifyContent="flex-end">
                        <Button
                            variant={'contained'}
                            color={'primary'}
                            size={'small'}
                            style={{ borderRadius: 50, margin: 15 }}
                            disableElevation
                            disabled={disabled}
                            type={'submit'}
                        >
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Paper>
    );
};

export default Contact;
