import React, { useContext, useEffect, useState } from 'react';
import MUIDataTable, { MUIDataTableColumn } from 'mui-datatables';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { useNavigate } from 'react-router-dom';

// components and helpers
import { NodeContext } from './Nodes';
import datatableTheme from '../../assets/styling/DatatableStyles';
import mobileDatatableTheme from '../../assets/styling/MobileDatatableStyles';
import { INodeProps } from '../../common/model/ExplorerModels';

// mui
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import ErrorIcon from '@mui/icons-material/Error';
import { ThemeProvider } from '@mui/material';
import { Hbar, HbarUnit } from '@hashgraph/sdk';
import { HocContext } from '../../common/context/hocContext';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

const muiCache = createCache({
    key: 'mui-datatables',
    prepend: true,
});

const NodeTableWrapper: React.FC = () => {
    const { hederaNodeData, hederaNodeError, onNodeRowSelection, onClearNodeRowSelection, selectedNodeData } =
        useContext(NodeContext);
    const { unitValue } = useContext(HocContext);
    const [currentNodeData, setCurrentNodeData] = useState<INodeProps[] | null | undefined>([]);
    const navigate = useNavigate();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));
    const tableTheme = {
        ...theme,
        components: matches ? datatableTheme : mobileDatatableTheme,
    };
    useEffect(() => {
        if (selectedNodeData) {
            const selectedNode: INodeProps[] | undefined = hederaNodeData?.filter(
                (node: any) => node.accountId === selectedNodeData[0].accountId,
            );
            setCurrentNodeData(selectedNode);
        } else {
            setCurrentNodeData(hederaNodeData);
        }
    }, [selectedNodeData, hederaNodeData]);

    const handleNodeDetail = (colData: any, cellMeta: { colIndex: number; rowIndex: number; dataIndex: number }) => {
        if (cellMeta.colIndex === 2) {
            const value = colData.props.children;
            navigate(`/search-details/account/${value}`);
        }
    };

    const columns = [
        {
            name: 'organization',
            label: 'Organization',
            options: {
                // eslint-disable-next-line react/display-name
                customBodyRender: (value: string) => {
                    return (
                        <Typography variant={'caption'} style={{ fontFamily: 'Inter, sans-serif' }}>
                            {value}
                        </Typography>
                    );
                },
            },
        },
        {
            name: 'id',
            label: 'Node ID',
            options: {
                // eslint-disable-next-line react/display-name
                customBodyRender: (value: string) => {
                    return (
                        <Typography variant={'caption'} style={{ fontFamily: 'Inter, sans-serif' }}>
                            {value}
                        </Typography>
                    );
                },
            },
        },
        {
            name: 'accountId',
            label: 'Account ID',
            options: {
                // eslint-disable-next-line react/display-name
                customBodyRender: (value: string) => {
                    return (
                        <Typography
                            variant={'caption'}
                            sx={[
                                {
                                    fontFamily: 'Inter, sans-serif',
                                    borderBottom: '1px dotted',
                                },
                                (theme) => ({
                                    '&:hover': {
                                        color: theme.palette.primary.main,
                                    },
                                }),
                            ]}
                        >
                            {value}
                        </Typography>
                    );
                },
            },
        },
        {
            name: 'latitude',
            label: 'Latitude',
            options: {
                display: false,
            },
        },
        {
            name: 'longitude',
            label: 'Longitude',
            options: {
                display: false,
            },
        },
        {
            name: 'numTransactions',
            label: 'Nb. of Transactions',
            options: {
                display: false,
                // eslint-disable-next-line react/display-name
                customBodyRender: (value: any) => {
                    return (
                        <Typography
                            variant={'caption'}
                            style={{ textAlign: 'center', fontFamily: 'Inter, sans-serif' }}
                        >
                            {value?.toLocaleString()}
                        </Typography>
                    );
                },
            },
        },
        {
            name: 'stake',
            label: 'Stake',
            options: {
                // eslint-disable-next-line react/display-name
                customBodyRender: (value: any, tableMeta: any) => {
                    const maxStake = tableMeta.rowData[tableMeta.columnIndex + 1];
                    const hbar = Hbar.fromTinybars(value).toString().split(' ');
                    const formattedHbar =
                        parseFloat(hbar[0]).toLocaleString(undefined, { maximumFractionDigits: 6 }) + ' ' + hbar[1];
                    const maxStakeHbar = Hbar.fromTinybars(maxStake).toString().split(' ');
                    const formattedMaxStakeHbar =
                        parseFloat(maxStakeHbar[0]).toLocaleString(undefined, { maximumFractionDigits: 6 }) +
                        ' ' +
                        maxStakeHbar[1];
                    return (
                        <div>
                            <Typography variant={'caption'} style={{ fontFamily: 'Inter, sans-serif' }}>
                                {unitValue === 'true' ? `${value?.toLocaleString()} tℏ` : formattedHbar}
                                {tableMeta.rowData[tableMeta.columnIndex + 1] < value && (
                                    <span>
                                        {' '}
                                        <Tooltip
                                            title={
                                                <div>
                                                    Exceeding the maximum stake for consensus.
                                                    <br />(
                                                    {unitValue === 'true'
                                                        ? `${maxStake?.toLocaleString()} tℏ`
                                                        : formattedMaxStakeHbar}
                                                    )
                                                </div>
                                            }
                                        >
                                            <ErrorOutlineOutlinedIcon fontSize="small" style={{ marginBottom: -5 }} />
                                        </Tooltip>
                                    </span>
                                )}
                            </Typography>
                        </div>
                    );
                },
            },
        },
        {
            name: 'max_stake',
            label: 'Max Stake',
            options: {
                display: false,
            },
        },
        {
            name: 'stake_not_rewarded',
            label: 'Stake Not Rewarded',
            options: {
                // eslint-disable-next-line react/display-name
                customBodyRender: (value: any) => {
                    const hbar = Hbar.fromTinybars(value).toString().split(' ');
                    const formattedHbar =
                        parseFloat(hbar[0]).toLocaleString(undefined, { maximumFractionDigits: 6 }) + ' ' + hbar[1];
                    return (
                        <Typography variant={'caption'} style={{ fontFamily: 'Inter, sans-serif' }}>
                            {unitValue === 'true' ? `${value?.toLocaleString()} tℏ` : formattedHbar}
                        </Typography>
                    );
                },
            },
        },
    ];

    const options = {
        onRowClick: onNodeRowSelection,
        onCellClick: handleNodeDetail,
        elevation: 0,
        download: false,
        filter: false,
        pagination: false,
        print: false,
        search: false,
        selectableRowsHeader: false,
        selectableRowsHideCheckboxes: true,
        viewColumns: false,
        responsive: 'simple',
        sortOrder: {
            name: 'id',
            direction: 'asc',
        },
        customSort: (data: any, colIndex: number, order: string) => {
            return data.sort((a: any, b: any) => {
                if (colIndex === 0) {
                    return (
                        (a.data[colIndex].toLowerCase() < b.data[colIndex].toLowerCase() ? -1 : 1) *
                        (order === 'desc' ? 1 : -1)
                    );
                } else {
                    if (colIndex === 2) {
                        const dataA = a.data[colIndex].split('.').pop();
                        const dataB = b.data[colIndex].split('.').pop();
                        return (dataA - dataB) * (order === 'asc' ? 1 : -1);
                    } else {
                        return (a.data[colIndex] - b.data[colIndex]) * (order === 'asc' ? 1 : -1);
                    }
                }
            });
        },
        textLabels: {
            body: {
                noMatch: (
                    <div>
                        {hederaNodeError ? (
                            <Grid container justifyContent={'center'} spacing={1}>
                                <Grid item>
                                    <ErrorIcon style={{ color: 'red', fontSize: 'medium' }} />
                                </Grid>
                                <Grid item>
                                    <Typography variant={'body2'} style={{ fontFamily: 'Inter, sans-serif' }}>
                                        {hederaNodeError}
                                    </Typography>
                                </Grid>
                            </Grid>
                        ) : (
                            <Grid container justifyContent={'center'} spacing={1}>
                                <Grid item>
                                    <Typography variant={'body2'} style={{ fontFamily: 'Inter, sans-serif' }}>
                                        Sorry, no matching records found
                                    </Typography>
                                </Grid>
                            </Grid>
                        )}
                    </div>
                ),
            },
        },
    };

    return (
        <Grid container justifyContent={'center'} style={{ maxHeight: matches ? 270 : 370, overflow: 'auto' }}>
            <Grid item xs={12}>
                <CacheProvider value={muiCache}>
                    <ThemeProvider theme={tableTheme}>
                        <MUIDataTable
                            title={''}
                            data={
                                hederaNodeError
                                    ? []
                                    : currentNodeData?.map((node) => ({
                                          ...node,
                                          stake: (node.stake_rewarded as number) + (node.stake_not_rewarded as number),
                                      })) || []
                            }
                            columns={columns}
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            options={options}
                        />
                    </ThemeProvider>
                </CacheProvider>
            </Grid>
            {selectedNodeData && (
                <Grid container justifyContent={'flex-end'}>
                    <Grid item>
                        <Button
                            size={'small'}
                            variant={'contained'}
                            color={'primary'}
                            style={{ marginTop: 20 }}
                            onClick={onClearNodeRowSelection}
                        >
                            Clear Selection
                        </Button>
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
};

export default NodeTableWrapper;
