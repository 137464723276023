import { ActionCreatorsMapObject, Dispatch, ActionCreator } from 'redux';
import { ThunkAction } from 'redux-thunk';
import axios from 'axios';
import { IExplorerState, IExplorerAction } from '../../common/model/ExplorerModels';

export const types = {
    GET_TRANSACTION_REQUEST: 'GET_TRANSACTION_REQUEST',
    GET_TRANSACTION_SUCCESS: 'GET_TRANSACTION_SUCCESS',
    GET_TRANSACTION_FAILURE: 'GET_TRANSACTION_FAILURE',
    RESET_TRANSACTIONS: 'RESET_TRANSACTIONS',
};

const API_ROOT = process.env.REACT_APP_HEDERA_PROXY;
const getTransactions: ActionCreator<ThunkAction<Promise<any>, IExplorerState, null, IExplorerAction>> =
    (limit: number, order: string, nextUrl?: string, transactionType?: string) => async (dispatch: Dispatch) => {
        dispatch({
            type: types.GET_TRANSACTION_REQUEST,
        });
        let url;
        if (nextUrl && transactionType) {
            url = `${API_ROOT}/transactions?limit=${limit}&order=${order}${nextUrl}`;
        } else if (nextUrl) {
            url = `${API_ROOT}/transactions?limit=${limit}&order=${order}${nextUrl}`;
        } else if (transactionType) {
            url = `${API_ROOT}/transactions?limit=${limit}&order=${order}&transactiontype=${transactionType}`;
        } else {
            url = `${API_ROOT}/transactions?limit=${limit}&order=${order}`;
        }

        try {
            const response = await axios({
                method: 'GET',
                url,
            });

            dispatch({
                type: types.GET_TRANSACTION_SUCCESS,
                payload: {
                    transactionData: response.data,
                    error: undefined,
                },
            });
        } catch (error) {
            console.log('Transaction Error', error);
            dispatch({
                type: types.GET_TRANSACTION_FAILURE,
                error: 'There was an issue fetching transaction data. Please try again later.',
            });
        }
    };

const resetTransactions: ActionCreator<ThunkAction<Promise<any>, IExplorerState, null, IExplorerAction>> =
    () => async (dispatch: Dispatch) =>
        dispatch({
            type: types.RESET_TRANSACTIONS,
        });

export const transactionActions: ActionCreatorsMapObject<
    ThunkAction<Promise<any>, IExplorerState, null, IExplorerAction>
> = {
    getTransactions,
    resetTransactions,
};
