import { gql } from '@apollo/client';

export const TX_TYPE_QUERY = gql`
    query TransactionTypes {
        getTransactionTypeStats {
            transactionType
            numberOfTransactions
        }
    }
`;

export const NODE_QUERY = gql`
    query Nodes {
        getNodeStats {
            nodeAccountId
            numberOfTransactions
        }
        getNodes {
            id
            accountId
            organization
            ips
            publicKey
            latitude
            longitude
        }
    }
`;

export const LAST_24_HOUR_QUERY = gql`
    query Last24Hours {
        getLast24HoursStats {
            time
            numberOfTransactions
            value
        }
    }
`;
