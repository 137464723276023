export const explorerPallet = [
    '#21215A',
    '#60CEBD',
    '#E99696',
    '#3E8BFF',
    '#2FA391',
    '#D4C304',
    '#84D0FF',
    '#BBC7D6',
    '#FF6A14',
    '#D5C897',
];
