import { ActionCreatorsMapObject, Dispatch, ActionCreator } from 'redux';
import { ThunkAction } from 'redux-thunk';
import axios from 'axios';
import { IExplorerState, IExplorerAction } from '../../common/model/ExplorerModels';
import moment from 'moment';
const HEDERA_API_ROOT = process.env.REACT_APP_HEDERA_API_ROOT;

export const types = {
    GET_MARKET_DATA_REQUEST: 'GET_MARKET_DATA_REQUEST',
    GET_MARKET_DATA_SUCCESS: 'GET_MARKET_DATA_SUCCESS',
    GET_MARKET_DATA_FAILURE: 'GET_MARKET_DATA_FAILURE',
    GET_PRICE_DATA_REQUEST: 'GET_PRICE_DATA_REQUEST',
    GET_PRICE_DATA_SUCCESS: 'GET_PRICE_DATA_SUCCESS',
    GET_PRICE_DATA_FAILURE: 'GET_PRICE_DATA_FAILURE',
    GET_STAKING_DATA_REQUEST: 'GET_STAKING_DATA_REQUEST',
    GET_STAKING_DATA_SUCCESS: 'GET_STAKING_DATA_SUCCESS',
    GET_STAKING_DATA_FAILURE: 'GET_STAKING_DATA_FAILURE',
    GET_NODE_DATA_REQUEST: 'GET_NODE_DATA_REQUEST',
    GET_NODE_DATA_SUCCESS: 'GET_NODE_DATA_SUCCESS',
    GET_NODE_DATA_FAILURE: 'GET_NODE_DATA_FAILURE',
    HANDLE_CRYTO_UNIT: 'HANDLE_CRYTO_UNIT',
    HANDLE_ENV: 'HANDLE_ENV',
    RESET_EXPLORER: 'RESET_EXPLORER',
};

const getMarketData: ActionCreator<ThunkAction<Promise<any>, IExplorerState, null, IExplorerAction>> =
    () => async (dispatch: Dispatch) => {
        dispatch({
            type: types.GET_MARKET_DATA_REQUEST,
        });

        try {
            const response = await axios({
                method: 'GET',
                url: `https://api.coingecko.com/api/v3/coins/hedera-hashgraph?market_data=true`,
            });

            dispatch({
                type: types.GET_MARKET_DATA_SUCCESS,
                payload: {
                    marketData: response.data,
                    error: undefined,
                },
            });
        } catch (error) {
            console.log('Market Error', error);
            dispatch({
                type: types.GET_MARKET_DATA_FAILURE,
                error: 'There was an issue fetching market data. Please try again later.',
            });
        }
    };

const getPriceData: ActionCreator<ThunkAction<Promise<any>, IExplorerState, null, IExplorerAction>> =
    () => async (dispatch: Dispatch) => {
        dispatch({
            type: types.GET_PRICE_DATA_REQUEST,
        });

        try {
            const response = await axios({
                method: 'GET',
                url: `https://api.coingecko.com/api/v3/coins/hedera-hashgraph/market_chart?vs_currency=usd&days=1`,
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            const reformattedData: any = [];
            response.data.prices.map((value: [string, number], i: number) => {
                const time = moment(value[0])['_d'].toString().split(' ')[4];
                const data = {
                    x: time.substring(0, time.lastIndexOf(':')),
                    y: value[1].toFixed(4),
                };
                reformattedData.push(data);
            });

            dispatch({
                type: types.GET_PRICE_DATA_SUCCESS,
                payload: {
                    priceData: [{ id: 'Price', data: reformattedData }],
                    error: undefined,
                },
            });
        } catch (error) {
            console.log('Price Error', error);
            dispatch({
                type: types.GET_PRICE_DATA_FAILURE,
                error: 'There was an issue fetching market data. Please try again later.',
            });
        }
    };

const getStakingData: ActionCreator<ThunkAction<Promise<any>, IExplorerState, null, IExplorerAction>> =
    () => async (dispatch: Dispatch) => {
        dispatch({
            type: types.GET_STAKING_DATA_REQUEST,
        });

        try {
            const response = await axios({
                method: 'GET',
                url: `${HEDERA_API_ROOT}/network/stake`,
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            dispatch({
                type: types.GET_STAKING_DATA_SUCCESS,
                payload: {
                    stakingData: response.data,
                    error: undefined,
                },
            });
        } catch (error) {
            console.log('Staking Error', error);
            dispatch({
                type: types.GET_STAKING_DATA_FAILURE,
                error: 'There was an issue fetching market data. Please try again later.',
            });
        }
    };

const getNodeData: ActionCreator<ThunkAction<Promise<any>, IExplorerState, null, IExplorerAction>> =
    (query: string) => async (dispatch: Dispatch) => {
        dispatch({
            type: types.GET_NODE_DATA_REQUEST,
        });

        try {
            const response = await axios({
                method: 'GET',
                url: `${HEDERA_API_ROOT}/network/nodes?${query}`,
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            dispatch({
                type: types.GET_NODE_DATA_SUCCESS,
                payload: {
                    data: response.data.nodes,
                    error: undefined,
                },
            });
        } catch (error) {
            console.log('Node Error', error);
            dispatch({
                type: types.GET_NODE_DATA_FAILURE,
                error: 'There was an issue fetching market data. Please try again later.',
            });
        }
    };

export const toggleCryptoUnit: ActionCreator<ThunkAction<Promise<any>, IExplorerState, null, IExplorerAction>> =
    (value: string) => async (dispatch: Dispatch) =>
        dispatch({
            type: types.HANDLE_CRYTO_UNIT,
            payload: { value },
        });

export const toggleEnv: ActionCreator<ThunkAction<Promise<any>, IExplorerState, null, IExplorerAction>> =
    () => async (dispatch: Dispatch) =>
        dispatch({
            type: types.HANDLE_ENV,
        });

const resetExplorer: ActionCreator<ThunkAction<Promise<any>, IExplorerState, null, IExplorerAction>> =
    () => async (dispatch: Dispatch) =>
        dispatch({
            type: types.RESET_EXPLORER,
        });

export const explorerActions: ActionCreatorsMapObject<
    ThunkAction<Promise<any>, IExplorerState, null, IExplorerAction>
> = {
    getMarketData,
    resetExplorer,
    toggleCryptoUnit,
    toggleEnv,
    getPriceData,
    getStakingData,
    getNodeData,
};
