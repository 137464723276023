import React from 'react';

// components and helpers
import ComponentWrapper from '../containers/ComponentWrapper';
import Explorer from '../components/Explorer/Explorer';

const HomeView: React.FC = () => {
    return <Explorer />;
};

export default ComponentWrapper(HomeView);
