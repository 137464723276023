import React, { useEffect, useState } from 'react';

// components and helpers
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import HederaOverview from '../components/Metrics/HederaOverview';
import LatestTxList from '../components/Metrics/LatestTxList';
import TxPieChart from '../components/Metrics/TxPieChart';
import DayChartContainer from '../components/Metrics/DayChartContainer';

const FrameView: React.FC = () => {
    const [allowCookies, setAllowCookies] = useState(false);

    useEffect(() => {
        const CookieName = 'TestCookie';
        document.cookie = 'CookieName=TestCookie;samesite=None; secure';
        if (document.cookie.indexOf(CookieName) == -1) {
            setAllowCookies(false);
        }
        if (document.cookie.indexOf(CookieName) != -1) {
            setAllowCookies(true);
        }
    }, []);

    return (
        <div>
            {allowCookies ? (
                <Grid container spacing={2} justifyContent={'center'} style={{ padding: 10 }}>
                    <Grid item xs={12} md={8}>
                        <Grid container spacing={2} justifyContent={'center'}>
                            <Grid item xs={12}>
                                <HederaOverview />
                            </Grid>
                            <Grid item xs={12}>
                                <LatestTxList frame />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Grid container spacing={2} justifyContent={'center'}>
                            <Grid item xs={12}>
                                <DayChartContainer frame />
                            </Grid>
                            <Grid item xs={12}>
                                <TxPieChart frame />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            ) : (
                <Grid container spacing={2} justifyContent={'center'} style={{ padding: 10 }}>
                    <Grid item xs={12} md={8}>
                        <Grid container spacing={2} justifyContent={'center'}>
                            <Grid item xs={12}>
                                <HederaOverview />
                            </Grid>
                            <Grid item xs={12}>
                                <Paper
                                    style={{ height: 325, borderRadius: 15, padding: '15px 10px 30px 10px' }}
                                    elevation={0}
                                >
                                    <Grid container justifyContent={'center'} alignItems={'flex-start'}>
                                        <Grid item xs={12} style={{ marginTop: 125 }}>
                                            <Typography
                                                variant={'body2'}
                                                style={{ textAlign: 'center', fontFamily: 'Inter, sans-serif' }}
                                            >
                                                This is a table which shows real-time transactions on Hedera Hashgraph
                                                for the last hour.
                                            </Typography>
                                            <Typography
                                                variant={'body2'}
                                                style={{ textAlign: 'center', fontFamily: 'Inter, sans-serif' }}
                                            >
                                                To view, please enable third-party cookies in your browser.
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Grid container spacing={2} justifyContent={'center'}>
                            <Grid item xs={12}>
                                <DayChartContainer frame />
                            </Grid>
                            <Grid item xs={12}>
                                <TxPieChart frame />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </div>
    );
};

export default FrameView;
