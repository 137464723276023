import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Grid from '@mui/material/Grid';
import loadingComponent from './loadingTextComponent';

export default function MarketSkeletonLoading() {
    return (
        <Grid container spacing={1} justifyContent={'center'}>
            <Grid item xs={12} md={7}>
                <div style={{ position: 'relative' }}>
                    {loadingComponent}
                    <Skeleton
                        variant={'rectangular'}
                        width={'100%'}
                        height={270}
                        style={{ borderRadius: 15 }}
                        animation={'wave'}
                    />
                </div>
            </Grid>
            <Grid item xs={12} md={5}>
                <div style={{ position: 'relative' }}>
                    {loadingComponent}
                    <Skeleton
                        variant={'rectangular'}
                        width={'100%'}
                        height={270}
                        style={{ borderRadius: 15 }}
                        animation={'wave'}
                    />
                </div>
            </Grid>
        </Grid>
    );
}
