export const features = [
    {
        title: 'Historical Data',
        description: 'Retrieve archived data beyond the provided 24 hours',
        icon: 'historical',
    },
    {
        title: 'Specific Timeframe',
        description:
            'Easily gather all ledger data, or ledger data for specific transactions, accounts and more from select timeframes',
        icon: 'timeframe',
    },
    {
        title: 'In-Depth Reporting',
        description:
            'Receive in-depth reporting of specific transactions, accounts, timeframes and more presented in a visually beautiful and intuitive way',
        icon: 'reporting',
    },
    {
        title: 'Beautiful Visualizations',
        description:
            'Receive in-depth reporting of specific transactions, accounts, timeframes and more presented in a visually beautiful and intuitive way',
        icon: 'visual',
    },
];
