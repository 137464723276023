import { Reducer } from 'redux';
import { types } from './TransactionsAction';
import { IExplorerAction, IExplorerState } from '../../common/model/ExplorerModels';

export const transactionsReducer: Reducer<IExplorerState, IExplorerAction> = (
    state: IExplorerState = {},
    action: IExplorerAction,
) => {
    switch (action.type) {
        case types.GET_TRANSACTION_REQUEST: {
            return {
                ...state,
                transactionDataLoading: true,
            };
        }
        case types.GET_TRANSACTION_SUCCESS: {
            return {
                ...state,
                transactionDataLoading: false,
                transactionData: action.payload?.transactionData,
            };
        }
        case types.GET_TRANSACTION_FAILURE: {
            return {
                ...state,
                transactionDataLoading: false,
                transactionDataError: action.error,
            };
        }
        case types.RESET_TRANSACTIONS: {
            return {};
        }
        default:
            return state;
    }
};
