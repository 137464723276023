import { ActionCreatorsMapObject, Dispatch, ActionCreator } from 'redux';
import { ThunkAction } from 'redux-thunk';
import axios from 'axios';
import { IGlobalAction, IGlobalState } from '../../common/model/GlobalModel';

const AUTH_URL = process.env.REACT_APP_AUTH_URL;
const AUTH_CLIENT_ID = process.env.REACT_APP_AUTH_CLIENT_ID;
const AUTH_CLIENT_SECRET = process.env.REACT_APP_AUTH_CLIENT_SECRET;
const AUTH_AUDIENCE = process.env.REACT_APP_AUTH_AUDIENCE;

export const types = {
    GET_M2M_TOKEN_REQUEST: 'GET_M2M_TOKEN_REQUEST',
    GET_M2M_TOKEN_SUCCESS: 'GET_M2M_TOKEN_SUCCESS',
    GET_M2M_TOKEN_FAILURE: 'GET_M2M_TOKEN_FAILURE',
};

const getM2MToken: ActionCreator<ThunkAction<Promise<any>, IGlobalState, null, IGlobalAction>> =
    () => async (dispatch: Dispatch) => {
        dispatch({
            type: types.GET_M2M_TOKEN_REQUEST,
        });

        try {
            const tokenResponse = await axios({
                method: 'POST',
                url: `${AUTH_URL}/oauth/token`,
                data: {
                    client_id: AUTH_CLIENT_ID,
                    client_secret: AUTH_CLIENT_SECRET,
                    audience: AUTH_AUDIENCE,
                    grant_type: 'client_credentials',
                },
            });

            const token = tokenResponse.data.access_token;
            const duration = tokenResponse.data.expires_in;

            dispatch({
                type: types.GET_M2M_TOKEN_SUCCESS,
                token,
                duration,
            });
        } catch (error) {
            console.error('Error trying to fetch the M2M token', error);
            dispatch({
                type: types.GET_M2M_TOKEN_FAILURE,
                error,
            });
        }
    };

export const tokenActions: ActionCreatorsMapObject<ThunkAction<Promise<any>, IGlobalState, null, IGlobalAction>> = {
    getM2MToken,
};
