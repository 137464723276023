import React, { ReactElement } from 'react';

// components and helpers
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

interface ISearchDetailProps {
    title?: string;
    detailType: ReactElement<any, any>;
}

const SectionDetail: React.FC<ISearchDetailProps> = ({ title, detailType }) => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));
    return (
        <Paper elevation={0} style={{ padding: matches ? 36 : 15, borderRadius: 15 }}>
            {title && (
                <Typography
                    variant={'h6'}
                    style={{ fontWeight: 'bold', fontFamily: 'Inter, sans-serif' }}
                    color={'secondary'}
                >
                    {title}
                </Typography>
            )}
            {detailType}
        </Paper>
    );
};

export default SectionDetail;
