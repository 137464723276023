import React from 'react';

// mui
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Tooltip from '@mui/material/Tooltip';
import CommentIcon from '@mui/icons-material/Comment';
import LanguageIcon from '@mui/icons-material/Language';
import builtOnHederaWhite from '../../assets/images/Built_on_Hedera_White.png';
import builtOnHederaBlack from '../../assets/images/Built_on_Hedera_Black.png';

interface IFooter {
    handleClickOpen: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const Footer: React.FC<IFooter> = ({ handleClickOpen }) => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));
    return (
        <Grid container alignItems={'center'} justifyContent={'center'} spacing={2}>
            <Grid item md={2} xs={4}>
                <Grid container alignItems={'center'} spacing={3}>
                    <Grid item>
                        <a href={'https://hedera.com/'} target={'_blank'} rel={'noreferrer'}>
                            <img
                                src={theme.palette.mode === 'light' ? builtOnHederaBlack : builtOnHederaWhite}
                                alt="Hedera Logo"
                                style={{
                                    maxHeight: 50,
                                    maxWidth: '100%',
                                    opacity: theme.palette.mode === 'light' ? 0.6 : 1,
                                }}
                            />
                        </a>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item md={8} xs={12}>
                <Grid container direction={'column'} alignItems={'center'}>
                    <Grid item>
                        <Grid container justifyContent={'center'} spacing={1}>
                            <Grid item>
                                <a href={'https://twitter.com/acoerco'} target={'_blank'} rel={'noreferrer'}>
                                    <IconButton>
                                        <TwitterIcon />
                                    </IconButton>
                                </a>
                            </Grid>
                            <Grid item>
                                <a
                                    href={'https://www.linkedin.com/company/acoer/'}
                                    target={'_blank'}
                                    rel={'noreferrer'}
                                >
                                    <IconButton>
                                        <LinkedInIcon />
                                    </IconButton>
                                </a>
                            </Grid>
                            <Grid item>
                                <a
                                    href={'https://www.youtube.com/channel/UC6F7E1Lw9P4uRo2n7nRAhwA/about'}
                                    target={'_blank'}
                                    rel={'noreferrer'}
                                >
                                    <IconButton>
                                        <YouTubeIcon />
                                    </IconButton>
                                </a>
                            </Grid>
                            <Grid item>
                                <a href={'https://www.instagram.com/acoerdotcom/'} target={'_blank'} rel={'noreferrer'}>
                                    <IconButton>
                                        <InstagramIcon />
                                    </IconButton>
                                </a>
                            </Grid>
                            <Grid item>
                                <a href={'https://www.facebook.com/Acoerco'} target={'_blank'} rel={'noreferrer'}>
                                    <IconButton>
                                        <FacebookIcon />
                                    </IconButton>
                                </a>
                            </Grid>
                            <Grid item>
                                <Tooltip title={'Acoer Website'}>
                                    <a href={'https://www.acoer.com'} target={'_blank'} rel={'noreferrer'}>
                                        <IconButton>
                                            <LanguageIcon />
                                        </IconButton>
                                    </a>
                                </Tooltip>
                            </Grid>
                            {!matches && (
                                <Grid item>
                                    <Tooltip title={'Contact Us'}>
                                        <IconButton onClick={handleClickOpen}>
                                            <CommentIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Typography variant={'caption'} color={'inherit'} style={{ fontFamily: 'Inter, sans-serif' }}>
                            Copyright ©Acoer, Inc 2022. All Rights Reserved.
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={2}></Grid>
        </Grid>
    );
};

export default Footer;
