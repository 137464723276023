import moment from 'moment/moment';

//Set authorization token automatically for API calls
const utils = {
    formatDate: (date: string, format = 'L HH:mm') => moment(date).format(format),
    toTitleTextUnderscore: (text: string) => {
        const splitText = text.replaceAll('_', ' ').toLowerCase();
        const result = splitText.replace(/([A-Z])/g, ' $1');
        return result.replace(/(^\w|\s\w)/g, (m) => m.toUpperCase());
    },
    toTitleText: (text: string) => {
        const result = text.replace(/([A-Z])/g, ' $1');
        return result.charAt(0).toUpperCase() + result.slice(1);
    },
    splitCamelcaseString: (string: string) => string.replace(/([a-z0-9])([A-Z])/g, '$1 $2'),
    convertToInternationalCurrencySystem: (value: any) => {
        // Eighteen Zeroes for Quintillions
        return Math.abs(Number(value)) >= 1.0e18
            ? Math.abs(Number(value)) / 1.0e18 + 'ab'.toString()
            : // Fifteen Zeroes for Quadrillions
            Math.abs(Number(value)) >= 1.0e15
            ? Math.abs(Number(value)) / 1.0e15 + 'aa'.toString()
            : // Twelve Zeroes for Trillions
            Math.abs(Number(value)) >= 1.0e12
            ? Math.abs(Number(value)) / 1.0e12 + 'T'.toString()
            : // Nine Zeroes for Billions
            Math.abs(Number(value)) >= 1.0e9
            ? Math.abs(Number(value)) / 1.0e9 + 'B'.toString()
            : // Six Zeroes for Millions
            Math.abs(Number(value)) >= 1.0e6
            ? Math.abs(Number(value)) / 1.0e6 + 'M'.toString()
            : // Three Zeroes for Thousands
            Math.abs(Number(value)) >= 1.0e3
            ? Math.abs(Number(value)) / 1.0e3 + 'K'.toString()
            : Math.abs(Number(value)).toString();
    },
    secondsToDhms: (seconds: number) => {
        seconds = Number(seconds);
        const d = Math.floor(seconds / (3600 * 24));
        const h = Math.floor((seconds % (3600 * 24)) / 3600);
        const m = Math.floor((seconds % 3600) / 60);

        const dDisplay = d > 0 ? d + (d == 1 ? ' day, ' : ' days ') : '';
        const hDisplay = h > 0 ? h + (h == 1 ? ' hour, ' : ' hours ') : '';
        const mDisplay = m > 0 ? m + (m == 1 ? ' minute, ' : ' minutes ') : '';
        return dDisplay + hDisplay + mDisplay;
    },
    txToApi: (data: string) => {
        return data.replace('@', '-').replace(/.([^.]*)$/, '-' + '$1');
    },
    txToReadable: (data: string) => {
        return data.replace('-', '@').replace('-', '.');
    },
};

export default utils;
