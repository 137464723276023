import * as React from 'react';
import { Hbar } from '@hashgraph/sdk';
import { RootStateOrAny, useSelector } from 'react-redux';
import { useContext, useEffect, useState } from 'react';

// components and helpers
import { HocContext } from '../../common/context/hocContext';

// mui
import Paper from '@mui/material/Paper';
import { Link, Stack, useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import Grid from '@mui/material/Grid';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ListItemText from '@mui/material/ListItemText';
import TollIcon from '@mui/icons-material/Toll';
import TimelapseIcon from '@mui/icons-material/Timelapse';

const StakingOverview: React.FC = () => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));
    const stakeData = useSelector((state: RootStateOrAny) => state.explorer.stakingData);
    const [formatHbar, setFormatHbar] = useState('');
    const [stakingTimeLeft, setStakingTimeLeft] = useState('0h 0m');
    const { unitValue } = useContext(HocContext);

    const toHoursAndMinutes = (totalSeconds: number) => {
        const totalMinutes = Math.floor(totalSeconds / 60);
        const hours = Math.floor(totalMinutes / 60);
        const minutes = totalMinutes % 60;

        setStakingTimeLeft(`${hours}h ${minutes}m`);
    };

    useEffect(() => {
        if (stakeData && stakeData.stake_total) {
            const hbar = Hbar.fromTinybars(stakeData.stake_total).toString().split(' ');
            const formattedHbar =
                parseFloat(hbar[0]).toLocaleString(undefined, { maximumFractionDigits: 6 }) + ' ' + hbar[1];
            setFormatHbar(formattedHbar);

            // calculate current date in seconds
            const ms = Date.now();
            const sec = Math.round(ms / 1000);
            // subtract current date from period to to get difference
            const difference = stakeData.staking_period.to - sec;
            toHoursAndMinutes(difference);
        }
    }, [stakeData]);

    return (
        <Paper
            elevation={0}
            style={{
                padding: 15,
                borderRadius: 15,
                backgroundColor: '#2FA391',
                color: '#fff',
                height: '100%',
            }}
        >
            <Grid container spacing={2}>
                <Grid item>
                    <NewReleasesIcon fontSize={'large'} />
                </Grid>
                {!matches && (
                    <Grid item xs={10}>
                        <Typography variant={'body1'} style={{ fontWeight: 'bold' }}>
                            Staking data now available in the HederaExplorer!
                        </Typography>
                    </Grid>
                )}
                <Grid item md={11} xs={12}>
                    {matches && (
                        <Typography variant={'body1'} style={{ fontWeight: 'bold' }}>
                            Staking data now available in the HederaExplorer!
                        </Typography>
                    )}
                    <Typography variant={'caption'}>
                        With the launch of the Hedera Staking Reward program, we have integrated staking data within
                        account details and network node details. As this program continues to grow, we will continue to
                        improve the analytics in the coming months for instant visibility into staking across the Hedera
                        platform.
                    </Typography>
                </Grid>
            </Grid>
            <Grid container justifyContent={'center'}>
                <Grid item xs={12}>
                    <List
                        dense
                        sx={{ width: '100%' }}
                        component={Stack}
                        direction={matches ? 'row' : 'column'}
                        justifyContent={'space-between'}
                    >
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar sx={{ bgcolor: '#0C2140', color: '#fff' }}>
                                    <AccessTimeIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                    <Typography
                                        variant={'caption'}
                                        style={{
                                            fontSize: 10,
                                            fontFamily: 'Inter, sans-serif',
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        STAKING PERIOD
                                    </Typography>
                                }
                                secondary={
                                    <Typography
                                        variant={'h6'}
                                        style={{
                                            fontFamily: 'Inter, sans-serif',
                                            fontSize: 14,
                                        }}
                                    >
                                        24 Hours
                                    </Typography>
                                }
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar sx={{ bgcolor: '#0C2140', color: '#fff' }}>
                                    <TollIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                    <Typography
                                        variant={'caption'}
                                        style={{
                                            fontSize: 10,
                                            fontFamily: 'Inter, sans-serif',
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        TOTAL STAKED
                                    </Typography>
                                }
                                secondary={
                                    <Typography
                                        variant={'h6'}
                                        style={{
                                            fontFamily: 'Inter, sans-serif',
                                            fontSize: 14,
                                            whiteSpace: 'nowrap',
                                        }}
                                    >
                                        {unitValue === 'true'
                                            ? `${(stakeData && stakeData.stake_total?.toLocaleString()) || 0} tℏ`
                                            : formatHbar || 0}
                                    </Typography>
                                }
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar sx={{ bgcolor: '#0C2140', color: '#fff' }}>
                                    <TimelapseIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                    <Typography
                                        variant={'caption'}
                                        style={{
                                            fontSize: 10,
                                            fontFamily: 'Inter, sans-serif',
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        NEXT STAKING PERIOD
                                    </Typography>
                                }
                                secondary={
                                    <Typography
                                        variant={'h6'}
                                        style={{
                                            fontFamily: 'Inter, sans-serif',
                                            fontSize: 14,
                                        }}
                                    >
                                        {stakingTimeLeft}
                                    </Typography>
                                }
                            />
                        </ListItem>
                    </List>
                </Grid>
                <Grid item>
                    <Link href={'/transactions?type=nodestakeupdate'} color={'inherit'} underline="hover">
                        <Typography variant={'caption'} style={{ fontWeight: 'bold' }}>
                            Click here to view latest staking transactions!
                        </Typography>
                    </Link>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default StakingOverview;
