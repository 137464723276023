import utils from '../../../common/utils/utils';

export const typeFormat = (type: string) => {
    switch (type) {
        case 'CONTRACTCALL':
            return 'CONTRACT CALL';
            break;
        case 'CONTRACTCREATEINSTANCE':
            return 'CONTRACT CREATE INSTANCE';
            break;
        case 'CONTRACTUPDATEINSTANCE':
            return 'CONTRACT UPDATE INSTANCE';
            break;
        case 'CRYPTOADDLIVEHASH':
            return 'CRYPTO ADD LIVE HASH';
            break;
        case 'CRYPTOCREATEACCOUNT':
            return 'CRYPTO CREATE ACCOUNT';
            break;
        case 'CRYPTODELETE':
            return 'CRYPTO DELETE';
            break;
        case 'CRYPTODELETELIVEHASH':
            return 'CRYPTO DELETE LIVE HASH';
            break;
        case 'CRYPTOTRANSFER':
            return 'CRYPTO TRANSFER';
            break;
        case 'CRYPTOUPDATEACCOUNT':
            return 'CRYPTO UPDATE ACCOUNT';
            break;
        case 'FILEAPPEND':
            return 'FILE APPEND';
            break;
        case 'FILECREATE':
            return 'FILE CREATE';
            break;
        case 'FILEDELETE':
            return 'FILE DELETE';
            break;
        case 'FILEUPDATE':
            return 'FILE UPDATE';
            break;
        case 'FREEZE':
            return 'FREEZE';
            break;
        case 'SYSTEMDELETE':
            return 'SYSTEM DELETE';
            break;
        case 'SYSTEMUNDELETE':
            return 'SYSTEM UNDELETE';
            break;
        case 'CONTRACTDELETEINSTANCE':
            return 'CONTRACT DELETE INSTANCE';
            break;
        case 'CONSENSUSCREATETOPIC':
            return 'CONSENSUS CREATE TOPIC';
            break;
        case 'CONSENSUSUPDATETOPIC':
            return 'CONSENSUS UPDATE TOPIC';
            break;
        case 'CONSENSUSDELETETOPIC':
            return 'CONSENSUS DELETE TOPIC';
            break;
        case 'CONSENSUSSUBMITMESSAGE':
            return 'CONSENSUS SUBMIT MESSAGE';
            break;
        case 'CRYPTODELETEALLOWANCE':
            return 'CRYPTO DELETE ALLOWANCE';
            break;
        case 'CRYPTOAPPROVEALLOWANCE':
            return 'CRYPTO APPROVE ALLOWANCE';
            break;
        case 'UNCHECKEDSUBMIT':
            return 'UNCHECKED SUBMIT';
            break;
        case 'TOKENCREATION':
            return 'TOKEN CREATION';
            break;
        case 'TOKENFREEZE':
            return 'TOKEN FREEZE';
            break;
        case 'TOKENUNFREEZE':
            return 'TOKEN UNFREEZE';
            break;
        case 'TOKENGRANTKYC':
            return 'TOKEN GRANT KYC';
            break;
        case 'TOKENREVOKEKYC':
            return 'TOKEN REVOKE KYC';
            break;
        case 'TOKENDELETION':
            return 'TOKEN DELETION';
            break;
        case 'TOKENUPDATE':
            return 'TOKEN UPDATE';
            break;
        case 'TOKENMINT':
            return 'TOKEN MINT';
            break;
        case 'TOKENBURN':
            return 'TOKEN BURN';
            break;
        case 'TOKENWIPE':
            return 'TOKEN WIPE';
            break;
        case 'TOKENASSOCIATE':
            return 'TOKEN ASSOCIATE';
            break;
        case 'TOKENDISSOCIATE':
            return 'TOKEN DISSOCIATE';
            break;
        case 'SCHEDULECREATE':
            return 'SCHEDULE CREATE';
            break;
        case 'SCHEDULEDELETE':
            return 'SCHEDULE DELETE';
            break;
        case 'SCHEDULESIGN':
            return 'SCHEDULE SIGN';
            break;
        case 'TOKENFEESCHEDULEUPDATE':
            return 'TOKEN FREE SCHEDULE UPDATE';
            break;
        case 'TOKENPAUSE':
            return 'TOKEN PAUSE';
            break;
        case 'TOKENUNPAUSE':
            return 'TOKEN UNPAUSE';
            break;
        case 'kyc_key':
            return 'KYC Key';
            break;
        case 'evm_address':
            return 'EVM Address';
            break;
        case 'ETHEREUMTRANSACTION':
            return 'ETHEREUM TRANSACTION';
            break;
        case 'NODESTAKEUPDATE':
            return 'NODE STAKE UPDATE';
            break;
        case 'UNKNOWN':
            return 'UNKNOWN';
            break;
        default:
            let value = utils.toTitleTextUnderscore(type);
            if (value.includes('Id')) {
                value = value.replace('Id', 'ID');
            }
            if (value.includes('Staked')) {
                value = value.replace('Staked', 'Stake');
            }
            return value;
    }
};

export const entityFormat = (type: string) => {
    if (type) {
        if (
            type === 'TOKENASSOCIATE' ||
            type === 'TOKENDISSOCIATE' ||
            type === 'TOKENFREEZE' ||
            type === 'TOKENGRANTKYC' ||
            type === 'TOKENREVOKEKYC' ||
            type === 'TOKENUNFREEZE'
        ) {
            return 'Account ID';
        } else if (type.includes('TOKEN')) {
            return 'Token ID';
        } else if (type.includes('SCHEDULE')) {
            return 'Schedule ID';
        } else if (type.includes('CONTRACT')) {
            return 'Contract ID';
        } else if (type.includes('CRYPTO')) {
            return 'Account ID';
        } else {
            return 'Topic ID';
        }
    } else {
        return 'Topic ID';
    }
};
