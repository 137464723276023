import React from 'react';
import { typeFormat } from './utils';
import Typography from '@mui/material/Typography';

export const nftColumns = (matches: boolean) => {
    return [
        {
            name: 'serial_number',
            label: 'Serial Number',
            options: {
                sort: false,
                setCellProps: () => ({
                    style: {
                        fontFamily: 'Inter, sans-serif',
                        fontSize: 12,
                        width: !matches ? '100%' : '10%',
                    },
                }),
            },
        },
        {
            name: 'account_id',
            label: 'Account ID',
            options: {
                sort: false,
                setCellProps: () => ({
                    style: {
                        fontFamily: 'Inter, sans-serif',
                        fontSize: 12,
                        width: !matches ? '100%' : '10%',
                    },
                }),
                // eslint-disable-next-line react/display-name
                customBodyRender: (value: string) => {
                    return value || 0;
                },
            },
        },
        {
            name: 'deleted',
            label: 'Deleted',
            options: {
                sort: false,
                setCellProps: () => ({
                    style: {
                        fontFamily: 'Inter, sans-serif',
                        fontSize: 12,
                        width: !matches ? '100%' : '10%',
                    },
                }),
                // eslint-disable-next-line react/display-name
                customBodyRender: (value: string) => {
                    return value.toString();
                },
            },
        },
        {
            name: 'created_timestamp',
            label: 'Created',
            options: {
                sort: false,
                setCellProps: () => ({
                    style: {
                        fontFamily: 'Inter, sans-serif',
                        fontSize: 12,
                        width: !matches ? '100%' : '15%',
                    },
                }),
                // eslint-disable-next-line react/display-name
                customBodyRender: (value: string) => {
                    return new Date(parseFloat(value) * 1000).toLocaleString();
                },
            },
        },
        {
            name: 'modified_timestamp',
            label: 'Modified',
            options: {
                sort: false,
                setCellProps: () => ({
                    style: {
                        fontFamily: 'Inter, sans-serif',
                        fontSize: 12,
                        width: !matches ? '100%' : '15%',
                    },
                }),
                // eslint-disable-next-line react/display-name
                customBodyRender: (value: string) => {
                    return new Date(parseFloat(value) * 1000).toLocaleString();
                },
            },
        },
        {
            name: 'metadata',
            label: 'Metadata',
            options: {
                sort: false,
                setCellProps: () => ({
                    style: {
                        maxWidth: 400,
                        wordBreak: 'break-all',
                        whiteSpace: 'wrap',
                        fontFamily: 'Inter, sans-serif',
                        fontSize: 12,
                    },
                }),
                // eslint-disable-next-line react/display-name
                customBodyRender: (value: string) => {
                    return (
                        <Typography
                            variant={'caption'}
                            style={{ fontFamily: 'Inter, sans-serif', whiteSpace: 'normal' }}
                        >
                            {Buffer.from(value, 'base64').toString()}
                        </Typography>
                    );
                },
            },
        },
    ];
};

export const messageColumns = (matches: boolean) => {
    return [
        {
            name: 'sequence_number',
            label: 'Sequence Number',
            options: {
                sort: false,
                setCellProps: () => ({
                    style: {
                        fontFamily: 'Inter, sans-serif',
                        fontSize: 12,
                        width: !matches ? '100%' : '20%',
                    },
                }),
            },
        },
        {
            name: 'payer_account_id',
            label: 'Payer Account ID',
            options: {
                sort: false,
                setCellProps: () => ({
                    style: {
                        fontFamily: 'Inter, sans-serif',
                        fontSize: 12,
                        width: !matches ? '100%' : '20%',
                    },
                }),
            },
        },
        {
            name: 'consensus_timestamp',
            label: 'Time',
            options: {
                sort: false,
                setCellProps: () => ({
                    style: {
                        fontFamily: 'Inter, sans-serif',
                        fontSize: 12,
                        width: !matches ? '100%' : '20%',
                    },
                }),
                // eslint-disable-next-line react/display-name
                customBodyRender: (value: string) => {
                    return new Date(parseFloat(value) * 1000).toLocaleString();
                },
            },
        },
        {
            name: 'message',
            label: 'Message',
            options: {
                sort: false,
                filter: false,
                setCellProps: () => ({
                    style: {
                        maxWidth: 500,
                        wordBreak: 'break-all',
                        whiteSpace: 'wrap',
                        fontFamily: 'Inter, sans-serif',
                        fontSize: 12,
                    },
                }),
                // eslint-disable-next-line react/display-name
                customBodyRender: (value: string) => {
                    return (
                        <Typography
                            variant={'caption'}
                            style={{ fontFamily: 'Inter, sans-serif', whiteSpace: 'normal' }}
                        >
                            {Buffer.from(value, 'base64').toString()}
                        </Typography>
                    );
                },
            },
        },
        // {
        //     name: 'running_hash',
        //     label: 'Running Hash',
        //     options: {
        //         setCellProps: () => ({
        //             style: {
        //                 fontFamily: 'Inter, sans-serif',
        //                 fontSize: 12,
        //             },
        //         }),
        //     },
        // },
    ];
};

export const signatureColumns = (matches: boolean) => {
    return [
        {
            name: 'type',
            label: 'Type',
            options: {
                sort: false,
                setCellProps: () => ({
                    style: {
                        cursor: 'auto',
                        width: !matches ? '100%' : '20%',
                    },
                }),
                // eslint-disable-next-line react/display-name
                customBodyRender: (value: string) => {
                    return (
                        <Typography variant={'caption'} style={{ fontFamily: 'Inter, sans-serif' }}>
                            {value}
                        </Typography>
                    );
                },
            },
        },
        {
            name: 'signature',
            label: 'Signature',
            options: {
                sort: false,
                setCellProps: () => ({
                    style: {
                        cursor: 'auto',
                        width: !matches ? '100%' : '60%',
                    },
                }),
                // eslint-disable-next-line react/display-name
                customBodyRender: (value: string) => {
                    return (
                        <Typography variant={'caption'} style={{ fontFamily: 'Inter, sans-serif' }}>
                            {value}
                        </Typography>
                    );
                },
            },
        },
        {
            name: 'consensus_timestamp',
            label: 'Time',
            options: {
                sort: false,
                setCellProps: () => ({
                    style: {
                        cursor: 'auto',
                        fontFamily: 'Inter, sans-serif',
                        fontSize: 12,
                    },
                }),
                // eslint-disable-next-line react/display-name
                customBodyRender: (value: string) => {
                    return new Date(parseFloat(value) * 1000).toLocaleString();
                },
            },
        },
    ];
};

export const tokenColumns = (matches: boolean) => {
    return [
        {
            name: 'token_id',
            label: 'Token ID',
            options: {
                sort: false,
                setCellProps: () => ({
                    style: {
                        fontFamily: 'Inter, sans-serif',
                        fontSize: 12,
                        width: !matches ? '100%' : '35%',
                    },
                }),
            },
        },
        {
            name: 'symbol',
            label: 'Token Symbol',
            options: {
                sort: false,
                setCellProps: () => ({
                    style: {
                        fontFamily: 'Inter, sans-serif',
                        fontSize: 12,
                        width: !matches ? '100%' : '35%',
                    },
                }),
            },
        },
        {
            name: 'balance',
            label: 'Balance',
            options: {
                sort: false,
                setCellProps: () => ({
                    style: {
                        fontFamily: 'Inter, sans-serif',
                        fontSize: 12,
                    },
                }),
                // eslint-disable-next-line react/display-name
                customBodyRender: (value: string) => {
                    return value.toLocaleString();
                },
            },
        },
    ];
};

export const txColumns = (matches: boolean) => {
    return [
        {
            name: 'transaction_id',
            label: 'ID',
            options: {
                sort: false,
                setCellProps: () => ({
                    style: {
                        fontFamily: 'Inter, sans-serif',
                        fontSize: 12,
                        width: !matches ? '100%' : '35%',
                    },
                }),
            },
        },
        {
            name: 'name',
            label: 'Type',
            options: {
                sort: false,
                setCellProps: () => ({
                    style: {
                        fontFamily: 'Inter, sans-serif',
                        fontSize: 12,
                        width: !matches ? '100%' : '35%',
                    },
                }),
                // eslint-disable-next-line react/display-name
                customBodyRender: (value: string) => {
                    return typeFormat(value);
                },
            },
        },
        {
            name: 'consensus_timestamp',
            label: 'Time',
            options: {
                sort: false,
                setCellProps: () => ({
                    style: {
                        fontFamily: 'Inter, sans-serif',
                        fontSize: 12,
                    },
                }),
                // eslint-disable-next-line react/display-name
                customBodyRender: (value: string) => {
                    return new Date(parseFloat(value) * 1000).toLocaleString();
                },
            },
        },
    ];
};

export const balanceColumns = (matches: boolean) => {
    return [
        {
            name: 'account',
            label: 'Account ID',
            options: {
                sort: false,
                setCellProps: () => ({
                    style: {
                        fontFamily: 'Inter, sans-serif',
                        fontSize: 12,
                        width: !matches ? '100%' : '50%',
                    },
                }),
            },
        },
        {
            name: 'balance',
            label: 'Balance',
            options: {
                sort: false,
                setCellProps: () => ({
                    style: {
                        fontFamily: 'Inter, sans-serif',
                        fontSize: 12,
                    },
                }),
                // eslint-disable-next-line react/display-name
                customBodyRender: (value: string) => {
                    return value.toLocaleString();
                },
            },
        },
    ];
};

export const nodeServiceColumns = (matches: boolean) => {
    return [
        {
            name: 'ip_address_v4',
            label: 'IP Address V4',
            options: {
                sort: false,
                setCellProps: () => ({
                    style: {
                        fontFamily: 'Inter, sans-serif',
                        fontSize: 12,
                        width: !matches ? '100%' : '50%',
                        cursor: 'auto',
                    },
                }),
            },
        },
        {
            name: 'port',
            label: 'Port',
            options: {
                sort: false,
                setCellProps: () => ({
                    style: {
                        fontFamily: 'Inter, sans-serif',
                        fontSize: 12,
                        cursor: 'auto',
                    },
                }),
            },
        },
    ];
};
