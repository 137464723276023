import React, { createContext, useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import TxDayChartsSkeletonLoading from '../Shared/LoadingSkeletons/TxDayChartsSkeletonLoading';
import TxLineChart from './TxLineChart';
import { useLazyQuery } from '@apollo/client';
import { LAST_24_HOUR_QUERY } from '../../graphql/gqlQueries';
import { IChartContextProps } from '../../common/model/ExplorerModels';
import TPSChart from './TPSChart';
import moment from 'moment';
import { ILatestTxListProps } from './LatestTxList';

export const ChartContext = createContext<IChartContextProps>(undefined!);
const DayChartContainer: React.FC<ILatestTxListProps> = ({ frame }) => {
    const [last24HoursBar, setLast24HoursBar] = useState<any>([]);
    const [last24HoursValue, setLast24HoursValue] = useState<any>([]);
    const [last24HoursVolume, setLast24HoursVolume] = useState<any>([]);
    const [last24HoursTime, setLast24Time] = useState<any>([]);

    const [get24Stats, { loading: dayStatDataLoading }] = useLazyQuery(LAST_24_HOUR_QUERY, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'cache-first',
        nextFetchPolicy: 'cache-first',
        onCompleted: (data) => {
            const last24StatsBar: any = [];
            const last24StatsValue: any = [];
            const last24StatsVolume: any = [];
            const last24TPS: any = [];
            const last24TxTime: any = [];
            const sortData = [...data.getLast24HoursStats];
            const sortedData: any = sortData.sort(function (a: any, b: any) {
                const dateA = new Date(a.time).getTime();
                const dateB = new Date(b.time).getTime();
                return dateA < dateB ? -1 : 1;
            });

            sortedData.map((stats: any) => {
                const time = moment.utc(stats.time).local().format('HH:mm').toString();
                const tps = (stats.numberOfTransactions / 3600).toFixed(2);
                const newTxStats = {
                    x: time,
                    y: stats.numberOfTransactions,
                };
                const newVolumeStats = {
                    x: time,
                    y: stats.value,
                };
                last24TxTime.push(time);
                last24StatsBar.push(stats.numberOfTransactions);
                last24TPS.push(tps);
                last24StatsValue.push(newTxStats);
                last24StatsVolume.push(newVolumeStats);
            });
            setLast24Time(last24TxTime);
            setLast24HoursBar([
                {
                    name: 'Transactions Per Hour',
                    type: 'column',
                    data: last24StatsBar,
                },
                {
                    name: 'Transactions Per Second',
                    type: 'line',
                    data: last24TPS,
                },
            ]);
            setLast24HoursValue([
                {
                    id: 'Transactions',
                    data: last24StatsValue,
                },
            ]);
            setLast24HoursVolume([
                {
                    id: 'Value',
                    data: last24StatsVolume,
                },
            ]);
        },
    });

    useEffect(() => {
        get24Stats();
    }, []);

    return (
        <ChartContext.Provider
            value={{
                last24HoursBar,
                last24HoursValue,
            }}
        >
            <Grid item xs={12}>
                {dayStatDataLoading ? (
                    <TxDayChartsSkeletonLoading height={310} />
                ) : (
                    <TPSChart
                        title={'Transaction History in Last 24 Hours'}
                        data={last24HoursBar}
                        categories={last24HoursTime}
                    />
                )}
            </Grid>
            {!frame && (
                <Grid item xs={12}>
                    {dayStatDataLoading ? (
                        <TxDayChartsSkeletonLoading />
                    ) : (
                        <TxLineChart title={'Transaction Value in Last 24 Hours'} data={last24HoursVolume} />
                    )}
                </Grid>
            )}
        </ChartContext.Provider>
    );
};

export default DayChartContainer;
