import { combineReducers } from 'redux';
import { explorerReducer } from '../../Explorer/ExplorerReducer';
import { IExplorerState } from '../../../common/model/ExplorerModels';
import { notifications } from '../../Notifications/NotificationReducer';
import { INotificationState } from '../../Notifications/notificationModel';
import { globalReducer } from '../GlobalReducer';
import { IGlobalState } from '../../../common/model/GlobalModel';
import { confirmationReducer } from '../../Confirmation/ConfirmationReducers';
import { IConfirmationState } from '../../Confirmation/ConfirmationModel';
import { transactionsReducer } from '../../Transactions/TransactionsReducer';
import { searchReducer } from '../../Search/SearchReducer';

// Root Reducer
const index = combineReducers({
    explorer: explorerReducer,
    confirmation: confirmationReducer,
    notifications,
    global: globalReducer,
    transactions: transactionsReducer,
    search: searchReducer,
});

export interface IExplorer {
    explorer?: IExplorerState;
    transactions?: IExplorerState;
    search?: IExplorerState;
    notifications?: INotificationState;
    global?: IGlobalState;
    confirmation?: IConfirmationState;
}

export default index;
