import React, { Component } from 'react';
import AcBreadcrumbs from './Utils/breadcrumbUtils';

class Breadcrumbs extends Component {
    render() {
        // define breadcrumbs
        const breadcrumbs = {
            transactions: {
                path: '/transactions',
                title: 'Transactions',
                breadcrumbs: {
                    'transaction-details': {
                        path: '/transaction-details',
                        title: 'Transaction Details',
                    },
                },
            },
            'search-details': {
                path: '/search-details',
                title: 'Search Details',
            },
        };

        return (
            <div style={{ marginLeft: '-30px', marginTop: 5 }}>
                <AcBreadcrumbs breadcrumbs={breadcrumbs} defaultLink="/" home="Explorer" />
            </div>
        );
    }
}

export default Breadcrumbs;
