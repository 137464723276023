import React from 'react';

// components and helpers
import Nodes from '../Nodes/Nodes';
import HederaOverview from '../Metrics/HederaOverview';
// import TxPieChart from '../Metrics/TxPieChart';
import LatestTxList from '../Metrics/LatestTxList';
import TxPriceChart from '../Metrics/TxPriceChart';
import TwitterFeed from '../Feed/TwitterFeed';
// import DayChartContainer from '../Metrics/DayChartContainer';
import StakingOverview from '../Metrics/StakingOverview';
import Feedback from '../Contact/Feedback';

// mui
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const Explorer: React.FC = ({}) => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));

    return (
        <Grid container justifyContent={'center'}>
            <Grid item xs={11} md={10}>
                <Grid container spacing={3} justifyContent={'center'}>
                    <Grid item>
                        <Grid container spacing={3}>
                            <Grid item xs={12} lg={7}>
                                <StakingOverview />
                            </Grid>
                            <Grid item xs={12} lg={5}>
                                <Feedback />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} lg={7}>
                        <HederaOverview />
                    </Grid>
                    <Grid item xs={12} lg={5}>
                        <TxPriceChart />
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={3} direction={matches ? 'row' : 'row-reverse'}>
                            <Grid item xs={12}>
                                <LatestTxList />
                            </Grid>
                            {/* <Grid item xs={12} md={5}>
                                <Grid container spacing={3}>
                                    <DayChartContainer />
                                    <Grid item xs={12}>
                                        <TxPieChart />
                                    </Grid>
                                </Grid>
                            </Grid> */}
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={9}>
                                <Nodes />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TwitterFeed />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Explorer;
