import React, { createContext, useContext, useState } from 'react';

// components and helpers
import { LeafletMap } from '@acoer/ac-js-lib-charts';

//mui
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import NodeTableWrapper from './NodeTableWrapper';
import { INodeContextProps, INodeProps } from '../../common/model/ExplorerModels';
import NodeDataSkeletonLoading from '../Shared/LoadingSkeletons/NodeDataSkeletonLoading';
import { AppContext } from '../../containers/AppProviderWrapper';

export const NodeContext = createContext<INodeContextProps>(undefined!);
const Nodes: React.FC = () => {
    const { hederaNodeData, hederaNodeCoordinates } = useContext(AppContext);
    const [selectedNodeCoordinates, setSelectedNodeCoordinates] = useState<[[number, number, string, string?]] | null>(
        null,
    );
    const [selectedNodeData, setSelectedNodeData] = useState<INodeProps[] | null>(null);
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));

    const onNodeRowSelection = (data: any[]) => {
        if (data[2]) {
            const selectedNode: [[number, number, string, string?]] = [
                [data[3], data[4], data[0].props.children, `Transactions: ${data[5].props.children}`],
            ];

            const selectedNodeData: INodeProps[] | undefined = hederaNodeData?.filter(
                (node: any) => node.accountId === data[2].props.children,
            );
            setSelectedNodeCoordinates(selectedNode);
            if (selectedNodeData) {
                setSelectedNodeData(selectedNodeData);
            }
        }
    };

    const onClearNodeRowSelection = () => {
        setSelectedNodeCoordinates(null);
        setSelectedNodeData(null);
    };

    if (!hederaNodeCoordinates) return <NodeDataSkeletonLoading />;
    return (
        <Card style={{ borderRadius: 15, height: 740 }} elevation={0}>
            <CardContent>
                <Typography variant="h6" component="p" style={{ fontWeight: 'bold', fontFamily: 'Inter, sans-serif' }}>
                    Hedera Council Nodes
                </Typography>
                <Divider style={{ marginBottom: 10 }} />
                <Grid container justifyContent={'center'} spacing={1}>
                    <Grid item xs={12} md={12} style={{ height: matches ? 300 : 400, width: '97%' }}>
                        <LeafletMap
                            data={
                                selectedNodeCoordinates
                                    ? (selectedNodeCoordinates as [[number, number, string, string]])
                                    : (hederaNodeCoordinates as [[number, number, string]])
                            }
                            secondaryData={
                                selectedNodeCoordinates
                                    ? (selectedNodeCoordinates as [[number, number, string, string]])
                                    : (hederaNodeCoordinates as [[number, number, string]])
                            }
                            type={'circleMark'}
                            onDownloadCsv={() => console.log('downloadCSV')}
                            zoom={matches ? 1 : 2}
                            center={[20.0, 0.0]}
                            popup
                            scrollWheelZoom={false}
                        />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <NodeContext.Provider
                            value={{
                                onNodeRowSelection,
                                onClearNodeRowSelection,
                                selectedNodeData,
                                selectedNodeCoordinates,
                                hederaNodeData,
                                hederaNodeCoordinates,
                            }}
                        >
                            <NodeTableWrapper />
                        </NodeContext.Provider>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default Nodes;
