import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ColorModeContext } from '../../App';
import { HocContext } from '../../common/context/hocContext';
import lightIcon from '../../assets/images/light_mode.png';
import darkIcon from '../../assets/images/dark_mode.png';
import tinyhbar from '../../assets/images/tinyhbar.svg';
import hbar from '../../assets/images/hbar.svg';
import { AppContext } from '../../containers/AppProviderWrapper';

// mui
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import { getThemeConfig } from './Utils/theme';
import { IconButton, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import Switch, { SwitchProps } from '@mui/material/Switch';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Menu from '@mui/material/Menu';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import MenuIcon from '@mui/icons-material/Menu';

const NavBar: React.FC = ({}) => {
    const theme = useTheme();
    const colorMode = useContext(ColorModeContext);
    const matches = useMediaQuery(theme.breakpoints.up('md'));
    const { handleCryptoUnitChange, unitValue } = useContext(HocContext);
    const { currentPath } = useContext(AppContext);
    const [envValue, setEnvValue] = useState('');
    const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleUnitChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const changeValue = e.target.checked;
        handleCryptoUnitChange(changeValue);
    };

    useEffect(() => {
        const url = window.location.host;
        const env = url?.includes('testnet') || url?.includes('localhost') ? 'test' : 'main';
        setEnvValue(env);
    }, []);

    const handleEnvChange = (e: { target: { value: any } }) => {
        const mainnet = 'https://hederaexplorer.io/';
        const testnet = 'https://testnet.hederaexplorer.io/';
        const change = e.target.value;
        if (change === envValue) {
            return;
        } else {
            window.open(change === 'test' ? testnet : mainnet, '_blank');
        }
    };

    const IOSSwitch = styled((props: SwitchProps) => (
        <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
    ))(({ theme }) => ({
        width: 67,
        height: 32,
        padding: 0,
        '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: 3.5,
            transitionDuration: '300ms',
            '&.Mui-checked': {
                transform: 'translateX(35px)',
                color: '#fff',
                '& + .MuiSwitch-track': {
                    backgroundColor: '#60CEBD',
                    opacity: 1,
                    border: 0,
                },
                '&.Mui-disabled + .MuiSwitch-track': {
                    opacity: 0.5,
                },
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
                color: '#60CEBD',
                border: '6px solid #fff',
            },
        },
        '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 25,
            height: 25,
            backgroundColor: '#333750',
        },
        '& .MuiSwitch-track': {
            borderRadius: 32 / 2,
            backgroundColor: '#60CEBD',
            opacity: 1,
            transition: theme.transitions.create(['background-color'], {
                duration: 500,
            }),
            '&:before, &:after': {
                content: '""',
                position: 'absolute',
                top: '70%',
                transform: 'translateY(-50%)',
                width: 32,
                height: 32,
            },
            '&:after': {
                content: `url(${hbar})`,
                backgroundAttachment: 'fixed',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                right: -7,
            },
            '&:before': {
                content: `url(${tinyhbar})`,
                backgroundAttachment: 'fixed',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                left: 7,
            },
        },
    }));

    return (
        <div>
            <AppBar position={'absolute'} elevation={0} style={{ backgroundColor: 'transparent' }}>
                <Toolbar>
                    <Grid container justifyContent={'center'}>
                        <Grid item xs={matches ? 10 : 12}>
                            <Grid container justifyContent={matches ? 'space-between' : 'center'} alignItems={'center'}>
                                <Grid item>
                                    <Link to={'/'} style={{ textDecoration: 'none' }}>
                                        <img
                                            src={getThemeConfig().logo}
                                            alt="HederaExplorer Logo"
                                            style={{ maxHeight: 60, maxWidth: '100%' }}
                                        />
                                    </Link>
                                </Grid>
                                <Grid item>
                                    <Grid
                                        container
                                        alignContent={'space-between'}
                                        alignItems={'center'}
                                        spacing={matches ? 4 : 1}
                                    >
                                        {matches && (
                                            <Grid item>
                                                <Grid container spacing={4}>
                                                    <Grid item>
                                                        <Link
                                                            to={'/'}
                                                            style={{
                                                                textDecoration: 'none',
                                                                color: '#fff',
                                                            }}
                                                        >
                                                            <Typography
                                                                style={{
                                                                    fontFamily: 'Inter, sans-serif',
                                                                    letterSpacing: '0.00938em',
                                                                    borderBottom:
                                                                        currentPath && currentPath === '/'
                                                                            ? '2px solid #2FA391'
                                                                            : 'none',
                                                                }}
                                                            >
                                                                Dashboard
                                                            </Typography>
                                                        </Link>
                                                    </Grid>
                                                    <Grid item>
                                                        <Link
                                                            to={'/faq'}
                                                            style={{
                                                                textDecoration: 'none',
                                                                color: '#fff',
                                                            }}
                                                        >
                                                            <Typography
                                                                style={{
                                                                    fontFamily: 'Inter, sans-serif',
                                                                    letterSpacing: '0.00938em',
                                                                    borderBottom:
                                                                        currentPath && currentPath === '/faq'
                                                                            ? '2px solid #2FA391'
                                                                            : 'none',
                                                                }}
                                                            >
                                                                FAQ
                                                            </Typography>
                                                        </Link>
                                                    </Grid>
                                                    <Grid item>
                                                        <Link
                                                            to={'/advanced-features'}
                                                            style={{ textDecoration: 'none', color: '#fff' }}
                                                        >
                                                            <Typography
                                                                style={{
                                                                    fontFamily: 'Inter, sans-serif',
                                                                    letterSpacing: '0.00938em',
                                                                    borderBottom:
                                                                        currentPath &&
                                                                        currentPath === '/advanced-features'
                                                                            ? '2px solid #2FA391'
                                                                            : 'none',
                                                                }}
                                                            >
                                                                Advanced Features
                                                            </Typography>
                                                        </Link>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        )}
                                        <Grid item>
                                            <FormControl variant="standard">
                                                <Select
                                                    value={envValue}
                                                    displayEmpty
                                                    onChange={handleEnvChange}
                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                    sx={{
                                                        '&:before': { borderColor: 'transparent' },
                                                        color: '#fff',
                                                        marginRight: matches ? 5 : 3,
                                                        '& .MuiSvgIcon-root': {
                                                            fill: '#fff',
                                                        },
                                                    }}
                                                    MenuProps={{
                                                        disableScrollLock: true,
                                                    }}
                                                >
                                                    <MenuItem value={'main'}>Mainnet</MenuItem>
                                                    <MenuItem value={'test'}>Testnet</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item>
                                            <FormControlLabel
                                                control={<IOSSwitch onChange={handleUnitChange} />}
                                                label=""
                                                name={'cryptoUnit'}
                                                checked={unitValue === 'true'}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <IconButton
                                                onClick={colorMode.toggleColorMode}
                                                color="inherit"
                                                disableRipple
                                            >
                                                {theme.palette.mode === 'dark' ? (
                                                    <img src={lightIcon} alt="" />
                                                ) : (
                                                    <img src={darkIcon} alt="" />
                                                )}
                                            </IconButton>
                                        </Grid>
                                        {!matches && (
                                            <Grid item>
                                                <Box sx={{ flexGrow: 0, marginLeft: 3 }}>
                                                    <IconButton
                                                        color="inherit"
                                                        disableRipple
                                                        onClick={handleOpenUserMenu}
                                                        sx={{ p: 0 }}
                                                    >
                                                        <MenuIcon />
                                                    </IconButton>
                                                    <Menu
                                                        sx={{ mt: '45px' }}
                                                        id="menu-appbar"
                                                        anchorEl={anchorElUser}
                                                        anchorOrigin={{
                                                            vertical: 'top',
                                                            horizontal: 'right',
                                                        }}
                                                        keepMounted
                                                        transformOrigin={{
                                                            vertical: 'top',
                                                            horizontal: 'right',
                                                        }}
                                                        open={Boolean(anchorElUser)}
                                                        onClose={handleCloseUserMenu}
                                                        disableScrollLock={true}
                                                    >
                                                        <Link
                                                            to={'/'}
                                                            style={{
                                                                textDecoration: 'none',
                                                                color: theme.palette.mode === 'dark' ? '#fff' : '#000',
                                                            }}
                                                        >
                                                            <MenuItem key={'home'} onClick={handleCloseUserMenu}>
                                                                <Typography>Dashboard</Typography>
                                                            </MenuItem>
                                                        </Link>
                                                        <Link
                                                            to={'/faq'}
                                                            style={{
                                                                textDecoration: 'none',
                                                                color: theme.palette.mode === 'dark' ? '#fff' : '#000',
                                                            }}
                                                        >
                                                            <MenuItem key={'faq'} onClick={handleCloseUserMenu}>
                                                                <Typography>FAQ</Typography>
                                                            </MenuItem>
                                                        </Link>
                                                        <Link
                                                            to={'/advanced-features'}
                                                            style={{
                                                                textDecoration: 'none',
                                                                color: theme.palette.mode === 'dark' ? '#fff' : '#000',
                                                            }}
                                                        >
                                                            <MenuItem key={'advanced'} onClick={handleCloseUserMenu}>
                                                                <Typography>Advanced Features</Typography>
                                                            </MenuItem>
                                                        </Link>
                                                    </Menu>
                                                </Box>
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
        </div>
    );
};

export default NavBar;
