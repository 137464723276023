import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MUIDataTable from 'mui-datatables';
import TimeAgo from 'react-timeago';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';

// hedera SDK
import { Hbar, HbarUnit } from '@hashgraph/sdk';

// components and helpers
import datatableTheme from '../../assets/styling/DatatableStyles';
import mobileDatatableTheme from '../../assets/styling/MobileDatatableStyles';
import { typeFormat } from '../Search/helpers/utils';
import CustomToolbar from './helpers/CustomToolbar';
import CheckCircle from '../../assets/images/CheckCircle.png';
import ErrorCircle from '../../assets/images/ErrorCircle.png';
import { HocContext } from '../../common/context/hocContext';
import TxListSkeletonLoading from '../Shared/LoadingSkeletons/TxListSkeletonLoading';

// mui
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Paper from '@mui/material/Paper';
import { ThemeProvider } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { transactionActions } from '../Transactions/TransactionsAction';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';

const muiCache = createCache({
    key: 'mui-datatables',
    prepend: true,
});
export interface ILatestTxListProps {
    frame?: boolean;
}
const LatestTxList: React.FC<ILatestTxListProps> = ({ frame }) => {
    const { unitValue } = useContext(HocContext);
    const dispatch = useDispatch();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));
    const tableTheme = {
        ...theme,
        components: matches ? datatableTheme : mobileDatatableTheme,
    };
    const navigate = useNavigate();
    // const { data: txSubDetailsData, loading } = useSubscription(TRANSACTION_DETAILS_SUBSCRIPTION);
    const transactionData = useSelector((state: RootStateOrAny) => state.transactions.transactionData);
    const transactionDataLoading = useSelector((state: RootStateOrAny) => state.transactions.transactionDataLoading);
    const [initialLoad, setInitialLoad] = useState(true);

    const handleRowClick = (data: any[]) => {
        const value = data[0].props.children;
        if (!frame) {
            navigate(`/search-details/transaction/${value}`);
        } else {
            window.open(`https://hederaexplorer.io/search-details/transaction/${value}`, '_blank');
        }
    };

    useEffect(() => {
        fetchLatestTx();
        setInitialLoad(false);
    }, []);

    // fetch latest data every second
    useEffect(() => {
        const fetchTxTimer = setInterval(() => {
            fetchLatestTx();
        }, 1000);
        return () => {
            clearInterval(fetchTxTimer);
            setInitialLoad(true);
        };
    }, []);

    const fetchLatestTx = useCallback(() => {
        dispatch(transactionActions?.getTransactions(25, 'desc'));
    }, []);

    const columns = [
        {
            name: 'transaction_id',
            label: 'ID',
            options: {
                setCellProps: () => ({ style: { minWidth: matches && 150, maxWidth: matches && 150 } }),
                sort: false,
                // eslint-disable-next-line react/display-name
                customBodyRender: (value: string) => {
                    return (
                        <Typography variant={'caption'} style={{ fontFamily: 'Inter, sans-serif' }}>
                            {value}
                        </Typography>
                    );
                },
            },
        },
        {
            name: 'node',
            label: 'Node',
            options: {
                sort: false,
                setCellProps: () => ({
                    style: { minWidth: matches && 30, maxWidth: matches && 30, textAlign: matches && 'center' },
                }),
                // eslint-disable-next-line react/display-name
                customBodyRender: (value: string) => {
                    return (
                        <Typography variant={'caption'} style={{ fontFamily: 'Inter, sans-serif' }}>
                            {value}
                        </Typography>
                    );
                },
            },
        },
        {
            name: 'name',
            label: 'Type',
            options: {
                sort: false,
                setCellProps: () => ({ style: { minWidth: matches && 100, maxWidth: matches && 100 } }),
                // eslint-disable-next-line react/display-name
                customBodyRender: (value: string) => {
                    return (
                        <Typography variant={'caption'} style={{ fontFamily: 'Inter, sans-serif' }}>
                            {typeFormat(value)}
                        </Typography>
                    );
                },
            },
        },
        {
            name: 'result',
            label: 'Result',
            options: {
                sort: false,
                setCellProps: () => ({
                    style: { minWidth: matches && 10, maxWidth: matches && 10, textAlign: matches && 'center' },
                }),
                // eslint-disable-next-line react/display-name
                customBodyRender: (value: string) => {
                    return (
                        <Grid
                            container
                            spacing={1}
                            style={{
                                margin: 0,
                                width: '100%',
                            }}
                        >
                            <Grid item>
                                {value === 'SUCCESS' ? (
                                    <img src={CheckCircle} alt="successIcon" />
                                ) : (
                                    <Tooltip title={value.replaceAll('_', ' ')}>
                                        <img src={ErrorCircle} alt="errorIcon" />
                                    </Tooltip>
                                )}
                            </Grid>
                        </Grid>
                    );
                },
            },
        },
        {
            name: 'charged_tx_fee',
            label: 'Tx Fee',
            options: {
                sort: false,
                setCellProps: () => ({ style: { minWidth: matches && 70, maxWidth: matches && 70 } }),
                // eslint-disable-next-line react/display-name
                customBodyRender: (value: any) => {
                    return (
                        <Typography variant={'caption'} style={{ fontFamily: 'Inter, sans-serif' }}>
                            {unitValue === 'true'
                                ? `${value?.toLocaleString()} tℏ`
                                : Hbar.from(value, HbarUnit.Tinybar)?.toLocaleString()}
                        </Typography>
                    );
                },
            },
        },
        {
            name: 'consensus_timestamp',
            label: 'Consensus',
            options: {
                sort: false,
                setCellProps: () => ({
                    style: {
                        minWidth: matches && 70,
                        maxWidth: matches && 70,
                        whiteSpace: matches && 'nowrap',
                        overflow: 'hidden',
                    },
                }),
                // eslint-disable-next-line react/display-name
                customBodyRender: (value: string) => {
                    const dateValue = new Date(parseFloat(value) * 1000);
                    return (
                        <Typography variant={'caption'} style={{ fontFamily: 'Inter, sans-serif', textAlign: 'right' }}>
                            <TimeAgo date={dateValue} key={Math.random()} />
                        </Typography>
                    );
                },
            },
        },
    ];

    const options = {
        onRowClick: handleRowClick,
        elevation: 0,
        download: false,
        filter: false,
        pagination: true,
        print: false,
        search: false,
        selectableRowsHeader: false,
        selectableRowsHideCheckboxes: true,
        viewColumns: false,
        rowsPerPage: frame ? 5 : 18,
        responsive: 'simple',
        customToolbar: () => {
            return !frame ? <CustomToolbar /> : null;
        },
        customFooter: () => {
            return null;
        },
        textLabels: {
            body: {
                noMatch: !transactionDataLoading && '',
            },
        },
    };

    const overloadComponent = (
        <div
            style={{
                position: 'absolute',
                zIndex: 110,
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Grid container justifyContent={'center'} alignItems={'flex-start'}>
                <Grid item xs={8}>
                    <Typography variant={'body2'} style={{ textAlign: 'center', fontFamily: 'Inter, sans-serif' }}>
                        This table shows the transactions for the last hour. We are currently processing older
                        transactions. This list will populate with the latest transactions soon.
                    </Typography>
                </Grid>
            </Grid>
        </div>
    );

    return (
        <Grid container justifyContent={'flex-end'}>
            {initialLoad && transactionDataLoading ? (
                <TxListSkeletonLoading />
            ) : (
                <Grid item xs={12}>
                    <CacheProvider value={muiCache}>
                        <ThemeProvider theme={tableTheme}>
                            <div style={{ position: 'relative' }}>
                                {!transactionDataLoading &&
                                    transactionData &&
                                    transactionData.transactions &&
                                    transactionData.transactions.length === 0 &&
                                    overloadComponent}
                                <Paper
                                    elevation={0}
                                    style={{
                                        padding: 5,
                                        borderRadius: 15,
                                        height: !frame && matches ? 899 : 325,
                                        overflow: 'auto',
                                    }}
                                >
                                    <MUIDataTable
                                        data={transactionData && transactionData.transactions}
                                        // data={
                                        //     (transactionData &&
                                        //         transactionData.transactions?.map((item: any) => ({
                                        //             ...item,
                                        //             amount: item.transfers.map((transfer: any) => {
                                        //                 // console.log(transfer.amount);
                                        //                 if (transfer.amount <= 0) {
                                        //                     return transfer.amount * -1;
                                        //                 } else {
                                        //                     return null;
                                        //                 }
                                        //             }),
                                        //         }))) ||
                                        //     []
                                        // }
                                        columns={columns}
                                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                        // @ts-ignore
                                        options={options}
                                        title={
                                            <Typography
                                                variant={'h5'}
                                                style={{ fontWeight: 'bold', fontFamily: 'Inter, sans-serif' }}
                                            >
                                                Latest Transactions
                                            </Typography>
                                        }
                                    />
                                </Paper>
                            </div>
                        </ThemeProvider>
                    </CacheProvider>
                </Grid>
            )}
        </Grid>
    );
};

export default LatestTxList;
