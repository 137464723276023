import * as React from 'react';

// components and helpers
import { MultiAxis } from '@acoer/ac-js-lib-charts';
import utils from '../../common/utils/utils';

// mui
import Paper from '@mui/material/Paper';
import { useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

export interface TxLineChartProps {
    title: string;
    data: any;
    categories: string[];
}

const TPSChart: React.FC<TxLineChartProps> = ({ title = '', data, categories }) => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));

    return (
        <Paper
            style={{
                height: matches ? 310 : 330,
                borderRadius: 15,
                padding: matches ? '15px 10px 30px 10px' : '15px 5px 50px 5px',
            }}
            elevation={0}
        >
            <MultiAxis
                series={data}
                // onDownloadCsv={() => console.log('download')}
                chartTitle={title}
                primaryText={theme.palette.mode === 'light' ? theme.palette.text.primary : '#D3D3D3'}
                customClasses={{ fontWeight: 'bold', fontFamily: 'Inter, sans-serif' }}
                customVariant={'h6'}
                showToolbar={matches}
                colourScheme={['#3E8BFF', '#FF7F7F']}
                chartTheme={theme.palette.mode}
                height={250}
                rightYTitle={'Transactions Per Second'}
                rightYSeries={'Transactions Per Second'}
                leftYTitle={'Transactions Per Hour'}
                categories={categories}
                yLeftFormatter={(value) => utils.convertToInternationalCurrencySystem(value)}
                tickAmount={12}
            />
        </Paper>
    );
};

export default TPSChart;
