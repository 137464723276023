import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Grid from '@mui/material/Grid';
import loadingComponent from './loadingTextComponent';

export default function NodeDataSkeletonLoading() {
    return (
        <Grid item xs={12}>
            <div style={{ position: 'relative' }}>
                {loadingComponent}
                <Skeleton
                    variant={'rectangular'}
                    width={'100%'}
                    height={740}
                    style={{ borderRadius: 15 }}
                    animation={'wave'}
                />
            </div>
        </Grid>
    );
}
